import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TermsModule } from './terms.module';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TermsService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    getService(): Observable<TermsModule[]> {
        return this.http.get<TermsModule[]>(environment.apiURL + 'General/GNTerms/');
    }

    getServiceId(id: number): Observable<TermsModule[]> {
        return this.http.get<TermsModule[]>(environment.apiURL + 'General/GNTerms/' + id);
    }
}
