<div class="{{navbarClass}}">
    <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
            <a class="navbar-brand" routerLink="/">
                <img src="assets/img/logo.png" alt="logo">
            </a>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>        

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">الرئيسية</a></li>



                    <li class="nav-item">
                        <a href="#" class="nav-link">مسيرة المشروع <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/story" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">قصة المشروع</a></li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/founder" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">عن المؤسس</a></li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/result" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">النتائج</a></li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/challenge_competition" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">مسابقة التحدي</a></li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/methodology" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">منهج المشروع</a></li>

                        </ul>
                    </li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/schools" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">المدارس المشاركة</a></li>


                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">تواصل معنا</a></li>

                </ul>
                <div class="others-options">
                </div>
            </div>
        </nav>
    </div>
</div>
