<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>نتائج المشروع</h2>
        </div>
    </div>
</div>


<div class="service-details ptb-100">
    <div class="container">
        <div class="service-details-image">
            <img src="assets/img/services-image/single-service.jpg" alt="image">
        </div>
        <div class="service-details-content">


            <h3>نتائج المشروع في السنتين الأولى والثانية:</h3>
            <p>
                أولاً:  أحدث إقامة هذا المشروع فرحاً وسروراً كبيراً لدى الطلابِ بإقامتِه وانضمامِهم إليه لتعلمِ التلاوة.
            </p>
            <p>
                ثانياً:  أحسَّ الطلابُ بمسؤوليةٍ كبيرةٍ ملقاةٍ على عاتقِهِم تجاهَ القرآنِ الكريم.
            </p>
            <p>
                ثالثاً: من الفوائدِ التي جنا ثمرتَها الطلابُ القراءةَ الدقيقةَ للكلماتِ بالتشكيل.
            </p>
            <p>
                رابعاً: تعلَّمَ الطلابُ بعضَ أحكامِ التجويدِ وتم تطبيقُها من خلالِ تلاوتِنا لسورِ جزءِ عمَّ وتبارك وقد سمع.
            </p>
            <p>
                خامساً: حَسَّنَ المشروعُ من فهمِ الطلابِ للقرآنِ الكريمِ وفتحَ عقولَهم على معانٍ كثيرة.
            </p>
            <p>
                سادساً: تم التغلب على مشكلة ضعف الطلابِ في أحكامِ التجويدِ وتلاوةِ القرآنِ الكريمِ وخصوصاً من الناحيةِ التطبيقية.
            </p>
            <p>
                سابعاً: تم ربط الطلابِ بالقرآنِ الكريمِ مما أشعرهم بحاجةٍ ملحةٍ لتلاوةِ وحفظِ القرآنِ الكريم.
            </p>
            <p>
                ثامناً: اكتشفنا مواهب رائعة كانت كامنة بين سطور النسيان وصفحات الإهمال مما كان له أثر في حصولهم على ثقة كبيرة في أنفسهم وفي قدراتهم.
            </p>
            <hr />
            <br />

            <h3>
                الجديد في مشروع تلاوة القرآن الكريم في السنة الدراسية الثالثة
            </h3>

            <h4>القاعدة النورانية</h4>
            <p>
                هي قاعدة تركز على تأسيس الطالب في القراءة ومعرفة التشكيل وتوصله إلى قراءة الكلمات والآيات قراءة صحيحة اعتمادا على نفسه دون أية صعوبة
                وكان مشروع تلاوة القرآن الكريم سباقا لتطبيقها ليكون حلا ناجعا لمشكلة ضعف التلاوة والقراءة لدى الطلاب والمتعلمين فجاء تدريسها ابتداء من السنة الثالثة لإقامة المشروع في العام الدراسي 1432 – 1433ه / 2011 – 2012م بمشاركة عشرة طلاب ثم توسعت المشاركة في السنة الرابعة ليصل العدد إلى خمسين طالبا ثم ارتفع العدد في السنة الدراسية الخامسة ( حصاد الخمس ) إلى أكثر من 150 طالبا وفي ( حصاد العشر ) إلى أكثر من 330 طالبا فكان مشروع التلاوة بوابة انتشار تعليم القاعدة النورانية في ربوع الولاية والولايات المجاورة والحمد لله رب العالمين.

            </p>


            <h4>نتائج المشروع في السنة الدراسية الثالثة:</h4>
            <p>أولاً: تمكن المشروع من تمكين الطلاب الاعتماد على نفسه في كيفية التلاوة الصحيحة وفي استخراج الأحكام.</p>
            <p>ثانياً: وصلنا هذه السنة وفي خط تصاعدي تعليمي إلى مرحلة دراسة مخارج وصفات الحروف.</p>
            <p>ثالثاً: بدأنا في هذه السنة في طريق تلاوة القرآن الكريم كاملا بتطبيق أحكام التجويد.</p>
            <p>رابعاً: ما زلنا نكتشف سنة بعد سنة مواهب رائعة كامنة بين سطور النسيان وصفحات الإهمال تملك الكثير من الإمكانيات قادرة على الإبداع.</p>
            <p>خامساً: بدأنا في هذه السنة كخطة تطويرية للمشروع تعليم القاعدة النورانية لأول مرة لعدد من طلاب الخامس الابتدائي وكانت النتيجة نجاح التجربة.</p>

            <hr />
            <br />

            <h4>نتائج المشروع في السنة الدراسية الرابعة:</h4>
            <p>أولا: ما زال المشروع متواصلا في دروس مخارج وصفات الحروف وبلغ عدد الطلاب الذين وصلوا إلى هذا المستوى 40 طالبا.</p>
            <p>ثانيا: بعد البداية الرائعة لفكرة تعليم القاعدة النورانية في السنة الماضية كخطة تطويرية لمشروع التلاوة فقد تم توسيع نطاق تعليمها وبلغ عدد الطلاب الذين أتقنوها وتدربوا عليها في هذه السنة 50 طالبا.</p>
            <p>ثالثا: انتشرت البقعة الجغرافية لمشروع التلاوة فقد وصل إلى 7 مدارس جديدة في ولايات السويق والخابورة والرستاق والعوابي بالإضافة إلى معهد العلوم الإسلامية بالسويق .</p>
            <p>رابعا: تشرف المشروع في هذه السنة بتخريج كوكبة من طلاب الثاني عشر الذين سيلتحقون بالمرحلة الجامعية فبعد أن تخرج في السنة الثالثة 7 طلاب وصل العدد في السنة الرابعة إلى 26 طالبا..</p>


            <hr />
            <br />


            <h4>نتائج المشروع في السنة الدراسية الخامسة ( حصاد الخمس ):</h4>
            <p>أولا: دخلت مجموعة جديدة مرحلة دراسة مخارج وصفات الحروف ليرتفع عدد الطلاب الذين وصلوا إلى هذه المرحلة إلى أكثر من 50 طالبا.</p>
            <p>ثانيا: وصل عدد الطلاب الذين أتقنوا تعلم القاعدة النورانية أكثر من 150 طالبا. </p>
            <p>ثالثا: دخول مجموعة جديدة من الأساتذة ( جزاهم الله خيرا ) ضمن برنامج المشروع وقد أدوا عملا كبيرا لتنمية مواهب الطلاب.</p>

            <hr />
            <br />

            <h4>الجديد في مشروع تلاوة القرآن الكريم في السنة الدراسية السادسة</h4>
            <p>
                بعد حصاد خمس سنوات رائعة أثمرت قاعدة طلابية واسعة أتقنت تلاوة القرآن الكريم وتأسيس قاعدة طلابية أخرى أتقنت تعلم القاعدة النورانية مما يجعلهم أكثر تركيزا في تلاوة القرآن الكريم، جاءت الفرصة سانحة وبشكل لا يقبل التأخير الزمني للانطلاق نحو إنشاء مركز لتحفيظ القرآن الكريم تابع للمشروع
                وذلك وفقا للمعطيات الآتية:
            </p>
            <p>1 – وجود مواهب رائعة مختزنة في عقول طلاب المشروع.</p>
            <p>2 – التهيئة النفسية والمعنوية والتطبيقية التي حصل عليها طلاب المشروع خلال خمس سنوات.</p>
            <p>3 – الرغبة الكبيرة لدى طلاب المشروع للمضي قدما نحو حفظ القرآن الكريم.</p>
            <p>4 – التطلع الكبير لدى المجتمع المدرسي والتربوي نحو الرقي بطلاب المدارس وخصوصا في جانب تلاوة وحفظ القرآن الكريم.</p>
            <p>5 – تطبيق الأفكار والرؤى التي كانت نتاج خمس سنوات من عمر مشروع تلاوة القرآن الكريم.</p>

            <p>وسيكون مركز التحفيظ مرتبطا ببرنامج مشروع تلاوة القرآن الكريم في المدارس والمساجد وذلك وفقا للبرنامج الزمني الآتي:</p>
            <p>1 – إقامة حلقة تحفيظ للقرآن الكريم في المدارس في وقت الفسحة وطابور الصباح.</p>
            <p>2 – إقامة حلقة تحفيظ للقرآن في المساجد والجوامع بين المغرب والعشاء ويوم السبت صباحا من الساعة 10 إلى 12.</p>
            <p>3 – إقامة برامج مكثفة في إجازة منتصف السنة الدراسية وفي الإجازة الصيفية.</p>


            <h4>نتائج المشروع في السنة الدراسية السادسة ( سادسة رابحة ):</h4>
            <p>1- دخول أكثر من 35 طالبا مرحلة دراسة مخارج وصفات الحروف ليرتفع عدد الطلاب الذين وصلوا إلى هذه المرحلة منذ تأسيس المشروع إلى أكثر من 80 طالبا </p>
            <p>2- القاعدة النورانية: إنهاء أكثر من 40 طالبا تعلم القاعدة النورانية ليصل العدد إلى أكثر من 190 طالبا تعلم القاعدة النورانية منذ تأسيس المشروع.</p>
            <p>3- توسعة المشروع إلى ثلاث مدارس جديدة في الولاية وهي مدارس الصبيخي والأحنف بن قيس وابن دريد للتعليم الأساسي.</p>
            <p>4- مركز تحفيظ القرآن الكريم:</p>
            <p>حفظ 12 جزء طالبان </p>
            <p>حفظ عشرة أجزاء طالب واحد </p>
            <p>حفظ تسعة أجزاء طالبان </p>
            <p>حفظ ثمانية أجزاء طالبان </p>
            <p>حفظ سبعة أجزاء ثلاثة طلاب </p>
            <p>حفظ ستة أجزاء عشرة طلاب </p>
            <p>حفظ خمسة أجزاء 14 طالبا </p>
            <p>حفظ أربعة أجزاء 20 طالبا </p>
            <p>حفظ ثلاثة أجزاء 48 طالبا </p>
            <p>حفظ جزئين 33 طالبا</p>

            <hr />
            <br />
            <h3>الجديد في مشروع تلاوة القرآن الكريم في السنة الدراسية السابعة</h3>
            <p>بعد مرور ست سنوات على تأسيس مشروع تلاوة القرآن الكريم متضمنا برنامج التلاوة التطبيقية ومرور سنة واحدة على تأسيس مركز تحفيظ القرآن الكريم تابع للمشروع جاءت فكرة لتطوير مستوى طلاب المشروع وتجويد مستواهم سواء في تلاوتهم التطبيقية أو في معلوماتهم التجويدية أو في حفظهم للقرآن الكريم وتم بلورة الفكرة وعمل التوصيف الكامل للبرنامج من خلال إجراء اختبارات مستمرة وتشكيل لجان خاصة بالبرنامج.</p>
            <p>وفي هذا التوصيف تفاصيل الاختبارات ومستوياتها والدروس التفصيلية لكل مستوى.</p>
            <h6>	الاختبارات</h6>
            <h6>	أولاً: الاختبار النظري ( العملي):</h6>
            <p>1.	يخصص كتاب القبس في علم التجويد كمرجع لدروس التجويد.</p>
            <p>2.	تخصيص مستويات وفق أسس محددة. </p>
            <p>3.	تخصيص دروس معينة لكل مستوى</p>
            <p>4.	يجرى اختبار لكل مستوى. </p>
            <p>5.	يتجاوز الطالب المستوى بدرجة 90 % فما فوق.</p>
            <p>6.	تحديد فترة زمنية لمواعيد الاختبارات.</p>


            <h6>	ثانياً: الاختبار التحريري:</h6>
            <p>1.	يخصص لكل اختبار جزء واحد.</p>
            <p>2.	يكون الاختبار على شكل أسئلة مقاطع.  </p>
            <p>3.	يتجاوز الطالب المستوى بدرجة 90 % فما فوق. </p>
            <p>4.	من يتجاوز ثلاثة أجزاء (عمَّ، تبارك، قد سمع) ينتقل للاختبار الشفوي الأول.</p>
            <p>5.	من يتجاوز الاختبار الشفوي الأول (عمَّ، تبارك، قد سمع) ينتقل للاختبار التحريري الرابع (الذاريات). </p>
            <p>6.	من يتجاوز ثلاثة أجزاء (الذاريات، الأحقاف، والشورى) ينتقل للاختبار الشفوي الثاني.</p>
            <p>7.	من يتجاوز الاختبار الشفوي الثاني ينتقل إلى الاختبار الشفوي الثالث (ستة أجزاء من عمَّ إلى الشورى).</p>


            <h6>	ثالثاً: الاختبار الشفوي:</h6>
            <p>1.	يكون على شكل أسئلة مقاطع.</p>
            <p>2.	كل أسئلة عبارة عن صفحة كاملة.</p>
            <p>3.	كل اختبار مكون من 6 أسئلة موزعة على أجزاء الاختبار.</p>
            <p>4.	يراعى فيه تطبيق أحكام التجويد الأساسية.</p>
            <p>5.	يتجاوز الطالب الاختبار بدرجة 90% فما فوق.</p>


            <h4>نتائج المشروع في السنة الدراسية السابعة ( سبع سمان ): </h4>
            <p>1- دخول 30 طالبا لمرحلة دراسة مخارج وصفات الحروف ليرتفع مجموع الطلاب الذين وصلوا إلى هذه المرحلة منذ تأسيس المشروع إلى أكثر من 120 طالبا.</p>
            <p>2- القاعدة النورانية: أنهى 55 طالبا تعلم القاعدة النورانية في هذه السنة ليصل العدد إلى أكثر من 245 طالبا تعلم القاعدة النورانية منذ تأسيس المشروع.</p>
            <p>3- مركز التحفيظ: </p>
            <p>ثلاثة طلاب حفظوا 15 جزءا</p>
            <p>4- برنامج المتابعة المستمر:</p>
            <p>المستوى الخامس ستة طلاب </p>
            <p>المستوى الرابع 13 طالبا </p>
            <p>المستوى الثالث 20 طالبا</p>


            <hr />
            <br />
            <h3>نتائج المشروع في السنة الدراسية الثامنة ( ثماني حجج ):</h3>
            <p>1- ارتفع عدد الطلاب في مرحلة دراسة مخارج وصفات الحروف إلى أكثر من 130 طالبا منذ تأسيس المشروع.</p>
            <p>2- القاعدة النورانية: أنهى 35 طالبا تعلم القاعدة النورانية ليصل العدد إلى أكثر من 280 طالبا تعلم القاعدة النورانية منذ تأسيس المشروع.</p>
            <p>3- مركز التحفيظ: وصلنا إلى حفظ 17 جزءا </p>
            <p>4- برنامج المتابعة المستمر:</p>
            <h6>أولا الاختبارات التحريرية: </h6>
            <p>مستوى 13 جزءا طالب واحد</p>
            <p>مستوى 11 جزءا طالب واحد </p>
            <p>مستوى ثمانية أجزاء طالب واحد</p>
            <p>مستوى سبعة أجزاء ثلاثة طلاب</p>
            <p>مستوى ستة أجزاء 12 طالبا</p>
            <p>مستوى خمسة أجزاء ستة طلاب</p>
            <p>مستوى أربعة أجزاء تسعة طلاب</p>
            <p>مستوى ثلاثة أجزاء 22 طالبا </p>
            <h6>ثانيا الاختبارات النظرية:</h6>
            <p> المستوى الثامن طالب واحد </p>
            <p>المستوى السابع ثلاثة طلاب</p>
            <p> المستوى السادس ثلاثة طلاب </p>
            <p>المستوى الخامس خمسة طلاب</p>
            <p>المستوى الرابع ثمانية طلاب</p>
            <p> المستوى الثالث 11 طالبا</p>


            <p>5- المسابقات: </p>
            <p>مسابقة حفظ القرآن الكريم لوزارة التربية والتعليم: </p>
            <p>تم تجهيز 15 طالبا على مستوى الولاية تأهل منهم ثلاثة طلاب على مستوى المحافظة تأهل منهم طالبان على مستوى السلطنة في مستوى عشرة أجزاء وأربعة أجزاء.</p>




            <hr />
            <br />
            <h3>نتائج المشروع في السنة الدراسية التاسعة ( وازدادوا تسعا ):</h3>
            <p>1- وصل أكثر من 150 طالبا إلى مرحلة مخارج وصفات الحروف منذ تأسيس المشروع.</p>
            <p>2- القاعدة النورانية: أنهى 25 طالبا تعلم القاعدة النورانية ليصل العدد إلى أكثر من 300 طالب تعلم القاعدة النورانية منذ تأسيس المشروع.</p>
            <p>3- حفظ القرآن الكريم:</p>
            <p>وصل أكثر من عشرة طلاب إلى حفظ سبعة أجزاء</p>
            <p>وصل ثلاثة طلاب إلى حفظ 15 جزء </p>
            <p>طالب واحد وصل إلى حفظ 18 جزء </p>
            <p>4- المسابقات: تم تجهيز أكثر من 30 طالبا </p>
            <p>-	لمسابقة السويق القرآنية</p>
            <p>-	ومسابقة السلطان قابوس للقرآن الكريم</p>
            <p>-	ومسابقة حفظ القرآن الكريم في وزارة التربية والتعليم: وفيها حصل طلاب المشروع على نتائج متقدمة حيث تأهل منهم ثلاثة طلاب على مستوى المحافظة تأهل منهم طالبان على مستوى السلطنة.</p>
            <p>-	أكثر من عشرة طلاب في مسابقة التلاوة والصوت الحسن لوزارة التربية والتعليم والحصول على المراكز الأولى.</p>
            <p></p>
            <p></p>







            <hr />
            <br />
            <h3>نتائج المشروع في السنة الدراسية العاشرة ( حصاد العشر ):</h3>
            <p>إحصائيات المشروع في السنة العاشرة – حصاد العشر -</p>
            <div class="service-details-image">
                <img src="assets/img/10.png" alt="image">
            </div>





            <hr />
            <br />
            <h3>نتائج المشروع في السنة الدراسية الرابعة عشر ( العود أحمد ):</h3>
            <p>أرقام قياسية في السنة الرابعة عشر – العود أحمد –</p>
            <p>مع دخول الطلاب الجدد في المشروع واصلت إدارة المشروع بكل قوة على تنمية مواهب الطلاب الكبيرة وتحسين جودة الحفظ والتلاوة لديهم مما كشف لنا عن نتائج مذهلة طوال هذا العام كان من أهمها تحقيقُ رقمين قياسيين :</p>
            <p>1 – 10 طلاب جدد تجاوزوا حفظ 3 أجزاء عن طريق الاختبارات الشفوية والكتابية وهذه سابقة لأول مرة تحدث في المشروع والحمد لله.</p>
            <p>2 – الرقم 325 يدل على عدد الاختبارات الكتابية والشفوية التي تجاوزها الطلاب سواء من القدامى أو الجدد خلال عام واحد فقط وهذا رقم قياسي لم يتحقق من قبل والحمد لله.</p>






            <hr />
            <br />
            <h3>نتائج المشروع في السنة الدراسية الخامسة عشر ( حصاد الخمسة عشر ):</h3>
            <p>إحصائيات المشروع في السنة الخامسة عشر – حصاد الخمسة عشر -</p>

            <div class="service-details-image">
                <img src="assets/img/15.png" alt="image">
            </div>



        </div>
    </div>
</div>


