<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>اعتماد الطالب</h2>
            <p>الخطوة الثالثة: بيانات الطالب</p>
        </div>
    </div>
</div>

<div class="service-details ptb-100">
    <div class="container">
        <div class="row align-items-center">




            <div class="">
                <div class="content left-content">
                    <h4>تأكيد وملأ كل بيانات الطالب</h4>
                    <br />


                    <form novalidate autocomplete="off" #form="ngForm">

                        <div class="row">
                            <div class="col-lg-6 col-md-6 form-group">
                                <br />
                                <label>الاسم الثلاثي والقبيلة</label>*
                                <input class="form-control form-control-lg" name="name" required
                                       #name="ngModel" [(ngModel)]="mainForm.name"
                                        [class.invalid]="name.invalid && name.touched">
                            </div>
                            <div class="col-lg-6 col-md-6 form-group">
                                <br />
                                <label>السنة الدراسية</label>*
                                <select required #mnAcademicYearID="ngModel" name="mnAcademicYearID" [(ngModel)]="mainForm.mnAcademicYearID" class="form-control form-control-lg" >
                                    <option *ngFor="let option of AcademicYearData" [value]="option.mnAcademicYearID">{{ option.name }}</option>
                                </select>
                            </div>
                            <div class="col-lg-6 col-md-6 form-group">
                                <br />
                                <label>رقم الهاتف</label>*
                                <input class="form-control form-control-lg" name="phoneNo" required
                                       #phoneNo="ngModel" [(ngModel)]="mainForm.phoneNo" onlyNumber
                                       [class.invalid]="phoneNo.invalid && phoneNo.touched">
                            </div>
                            <div class="col-lg-6 col-md-6 form-group">
                                <br />
                                <label>رقم هاتف آخر</label>
                                <input class="form-control form-control-lg" name="otherPhoneNo"
                                       #otherPhoneNo="ngModel" [(ngModel)]="mainForm.otherPhoneNo" onlyNumber
                                       [class.invalid]="otherPhoneNo.invalid && otherPhoneNo.touched">
                            </div>
                            <div class="col-lg-6 col-md-6 form-group">
                                <br />
                                <label>تاريخ الميلاد</label>*
                                <input type="text" placeholder="DD/MM/YYYY" name="dob"
                                       #dob="ngModel" [(ngModel)]="mainForm.dob" 
                                       class="form-control form-control2"
                                       bsDatepicker
                                       [bsValue]="bsValue"
                                       [maxDate]="maxDate"
                                       [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY' }">
                            </div>
                            <div class="col-lg-6 col-md-6 form-group">
                                <br />
                                <label>الرقم المدني</label>*
                                <input class="form-control form-control-lg" name="civilID" 
                                       #civilID="ngModel" [(ngModel)]="mainForm.civilID"
                                       [class.invalid]="civilID.invalid && civilID.touched">
                            </div>
                            <div class="col-lg-6 col-md-6 form-group">
                                <br />
                                <label>الولاية</label>*
                                <select required #gnAddressID="ngModel" name="gnAddressID" [(ngModel)]="mainForm.gnAddressID" class="form-control form-control-lg">
                                    <option *ngFor="let option of gnAddressData" [value]="option.gnAddressID">{{ option.name }}</option>
                                </select>
                            </div>
                            <div class="col-lg-6 col-md-6 form-group">
                                <br />
                                <label>القرية</label>*
                                <input class="form-control form-control-lg" name="village" required
                                       #village="ngModel" [(ngModel)]="mainForm.village"
                                       [class.invalid]="village.invalid && village.touched">
                            </div>
                            <div class="col-lg-6 col-md-6 form-group">
                                <br />
                                <label>المدرسة</label>*
                                <select required #mnSchoolID="ngModel" name="mnSchoolID" [(ngModel)]="mainForm.mnSchoolID" class="form-control form-control-lg">
                                    <option *ngFor="let option of mnSchoolData" [value]="option.mnSchoolID">{{ option.name }}</option>
                                </select>
                            </div>
                            <div class="col-lg-6 col-md-6 form-group">
                                <br />
                                <label>الصف</label>*
                                <select required #class="ngModel" name="class" value="الخامس" [(ngModel)]="mainForm.class" class="form-control form-control-lg">
                                    <option value="الخامس">الخامس</option>
                                    <option value="السادس">السادس</option>
                                    <option value="السابع">السابع</option>
                                    <option value="الثامن">الثامن</option>
                                    <option value="التاسع">التاسع</option>
                                    <option value="العاشر">العاشر</option>
                                    <option value="الحادي عشر">الحادي عشر</option>
                                    <option value="الثاني عشر">الثاني عشر</option>
                                </select>
                            </div>
                            <div class="col-lg-6 col-md-6 form-group">
                                <br />
                                <label>الشعبة</label>*
                                <select required #room="ngModel" name="room" value="غير محدد" [(ngModel)]="mainFormStudentYear.room" class="form-control form-control-lg">
                                    <option value="غير محدد">غير محدد</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                </select>
                            </div>


                        </div>

                        <hr />

                        <p>

                        </p>
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label> وصف موقع السكن أو البيت مع أقرب معلم <span>*</span></label>
                                    <textarea name="accommodationDescription" id="accommodationDescription" #accommodationDescription="ngModel" [(ngModel)]="mainFormStudent.accommodationDescription" class="form-control" placeholder="" required></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <p class="text-danger">
                                    الرجاء تكرما تحديد الموقع الجغرافي عبر الضغط على زر موقعي الحالي والسماح بتحديد الموقع أو سحب الدبوس لموقع البيت لتسهيل التنسيق مع سائق الحافلة
                                </p>


                                <button class="default-btn" name="locationBtn" (click)="getLocation()">موقعي الحالي</button>


                                <agm-map [latitude]="lat"
                                         [longitude]="lng"
                                         [zoom]="zoom"
                                         [disableDefaultUI]="false"
                                         [zoomControl]="false">

                                    <agm-marker *ngFor="let m of markers; let i = index"
                                                [latitude]="m.lat"
                                                [longitude]="m.lng"
                                                [markerDraggable]="m.draggable"
                                                (dragEnd)="markerDragEnd(m, $event)">

                                        <agm-info-window>
                                            <strong>{{m.label}}</strong>
                                        </agm-info-window>

                                    </agm-marker>


                                </agm-map>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <br />
                            <button type="button" class="default-btn" name="submit_button" [disabled]="(form.invalid || isDisabled)" (click)="onClickBT()">التالي</button>
                        </div>
                    </form>

                </div>

            </div>
        </div>


    </div>
</div>

