import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { RegistrationModule } from './registration.module';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };
     
    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }


    getService(): Observable<RegistrationModule[]> {
        return this.http.get<RegistrationModule[]>(environment.apiURL + 'Main/MNRegistration/');
    }

    getServiceFindParent(phone: string, id: number): Observable<RegistrationModule[]> {
        return this.http.get<RegistrationModule[]>(environment.apiURL + 'View/ViewMNRegistration/FindParent/?phone=' + phone + '&id=' + id);
    }
    postService(formData: RegistrationModule) {

      formData.dob = this.convetMoment(formData.dob);

        return this.http.post(environment.apiURL + 'Main/MNRegistration/', formData);
    }

    putService(formData: RegistrationModule) {

        formData.dob = this.convetMoment(formData.dob);

        return this.http.put(environment.apiURL + 'Main/MNRegistration/' + formData.mnRegistrationID, formData);
    }

    getServiceSendMessage(mnRegistrationID: number, type: string) {
        return this.http.get(environment.apiURL + "Main/MNRegistration/SendMessage/?mnRegistrationID=" + mnRegistrationID + "&type=" + type, { responseType: 'text' });
    }


    convetMoment(startDate: Date): Date {
        let momentToDate = moment(startDate).toDate();
        return new Date(Date.UTC(momentToDate.getFullYear(), momentToDate.getMonth(), momentToDate.getDate(), momentToDate.getHours(), momentToDate.getMinutes(), momentToDate.getSeconds()));
    }
}
