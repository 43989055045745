<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/logo_white.png" style="max-height:80px" alt="image"></a>
                    </div>
                    <p>مشروع تلاوة القرآن الكريم . أسسه الأستاذ / سيف الكيومي واعظ ولاية السويق بوزارة الأوقاف والشؤون الدينية. مقره ومركزه ولاية السويق وهو مشروع مختص في تلاوة وتحفيظ القرآن الكريم ، ويسعى إلى تفعيل رسالة المسجد في الإسلام من خلال إنشاء مركز لتلاوة وحفظ القرآن الكريم بولاية السويق ، كما سعى أصحاب المبادرة الكريمة بعمل أول انطلاقة من خلال إقامة حلقة تحفيظ القرآن الكريم في المدارس في وقت الفسحة وطابور الصباح والإجازة. كما يهدف المركز إلى الارتقاء بمستوى الحفاظ في الولاية ولقد استطاع المركز في فترة وجيزة قطع شوط كبير في مجال تلاوة وتحفيظ القرآن الكريم </p>
                </div>
            </div>
           
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>روابط سريعة</h3>
                    <ul class="support-list">
                        <li><a routerLink="/">الرئيسية</a></li>
                        <li><a routerLink="/">قصة المشروع</a></li>
                        <li><a routerLink="/">مؤسس المشروع</a></li>
                        <li><a routerLink="/">المدارس المشاركة</a></li>
                        <li><a routerLink="/">تواصل معنا</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>معلومات التواصل</h3>
                    <ul class="footer-contact-info">
                        <li>الموقع: <a href="#">سلطنة عمان، ولاية السويق</a></li>
                        <li>البريد الإلكتروني: <a href="mailto:info@telawh.com">info@telawh.com</a></li>
                        <li>رقم الهاتف: <a href="tel:+96895159021">95159021 968+</a></li>
                    </ul>
                    <ul class="social">
                        <li><a href="https://www.facebook.com/qurantlawah/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="https://twitter.com/saifalkiumi" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="https://youtube.com/@telawh_om" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                        <li><a href="https://www.instagram.com/telawh.om" target="_blank"><i class="bx bxl-instagram-alt"></i></a></li>
                        <li><a href="https://whatsapp.com/channel/0029VaOCwA05kg7FQQuRP637" target="_blank"><i class="bx bxl-whatsapp-square"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <p><a href="http://d-pioneers.com/" target="_blank">Digital Pioneers</a> Powered by </p>
        </div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-chevron-up'></i></div>
