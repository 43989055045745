import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RecitationLecturesAttendanceM, RecitationLecturesAttendanceModule, RecitationLecturesAttendance } from './recitation-lectures-attendance.module';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecitationLecturesAttendanceService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }


    getService(): Observable<RecitationLecturesAttendanceModule[]> {
        return this.http.get<RecitationLecturesAttendanceModule[]>(environment.apiURL + 'Main/MNRecitationLecturesAttendance/');
    }
   

    getServiceStudentYearAttendance(mnGroupID: number, mnRecitationLecturesID: number): Observable<RecitationLecturesAttendanceM[]> {
        return this.http.get<RecitationLecturesAttendanceM[]>(environment.apiURL + 'Main/MNRecitationLecturesAttendance/GetStudentYearAttendance/?MNGroupID=' + mnGroupID + '&MNRecitationLecturesID=' + mnRecitationLecturesID);
    }

    getServiceStudentId(StudentId: number): Observable<RecitationLecturesAttendanceModule[]> {
        return this.http.get<RecitationLecturesAttendanceModule[]>(environment.apiURL + 'View/ViewRecitationLecturesAttendance/SearchStudent/?StudentID=' + StudentId);
    }

}
