<div class="preloader-area">
    <div class="spinnerLogo">
        <div class="inner">

            <img class="" src="../../../../assets/img/logo_white.png" />
        </div>
    </div>
    <!--<div class="spinnerLogo">-->

    <!--</div>-->
</div>
