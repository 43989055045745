import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-terms',
  templateUrl: './register-terms.component.html',
  styleUrls: ['./register-terms.component.scss']
})
export class RegisterTermsComponent implements OnInit {

    constructor(private router: Router) { }

  ngOnInit(): void {
  }
    public onClickBT() {

        this.router.navigate(['register_details']);

    }
}
