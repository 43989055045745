<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>التسجيل في المشروع</h2>
            <p>الخطوة الثانية: بيانات الطالب</p>
        </div>
    </div>
</div>

<div class="service-details ptb-100">
    <div class="container">
        <div class="row align-items-center">




            <div class="">
                <div class="content left-content">
                    <h4>الرجاء ملأ كل البيانات</h4>
                    <br />


                    <form novalidate autocomplete="off" #form="ngForm" (submit)="onSubmit(form)" >

                        <div class="row">
                            <div class="col-lg-6 col-md-6 form-group">
                                <br />
                                <label>الاسم الثلاثي والقبيلة</label>*
                                <input class="form-control form-control-lg" name="name" required 
                                       #name="ngModel" [(ngModel)]="mainForm.name"
                                       required [class.invalid]="name.invalid && name.touched">
                            </div>
                            <div class="col-lg-6 col-md-6 form-group">
                                <br />
                                <label>السنة الدراسية</label>*
                                <select required #mnAcademicYearID="ngModel" name="mnAcademicYearID" [(ngModel)]="mainForm.mnAcademicYearID" class="form-control form-control-lg" required>
                                    <option *ngFor="let option of AcademicYearData" [value]="option.mnAcademicYearID">{{ option.name }}</option>
                                </select>
                            </div>
                            <div class="col-lg-6 col-md-6 form-group">
                                <br />
                                <label>رقم الهاتف</label>*
                                <input class="form-control form-control-lg" name="phoneNo" required
                                       #phoneNo="ngModel" [(ngModel)]="mainForm.phoneNo" onlyNumber
                                       [class.invalid]="phoneNo.invalid && phoneNo.touched">
                            </div>
                            <div class="col-lg-6 col-md-6 form-group">
                                <br />
                                <label>رقم هاتف آخر</label>
                                <input class="form-control form-control-lg" name="otherPhoneNo" 
                                       #otherPhoneNo="ngModel" [(ngModel)]="mainForm.otherPhoneNo" onlyNumber
                                       [class.invalid]="otherPhoneNo.invalid && otherPhoneNo.touched">
                            </div>
                            <div class="col-lg-6 col-md-6 form-group">
                                <br />
                                <label>تاريخ الميلاد</label>
                                <input type="text" placeholder="DD/MM/YYYY" name="dob"
                                       #dob="ngModel" [(ngModel)]="mainForm.dob" required
                                       [class.invalid]="dob.invalid && dob.touched"
                                       class="form-control form-control2"
                                       bsDatepicker
                                       [bsValue]="bsValue"
                                       [maxDate]="maxDate"
                                       [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY' }">
                            </div>
                            <div class="col-lg-6 col-md-6 form-group">
                                <br />
                                <label>الرقم المدني</label>
                                <input class="form-control form-control-lg" name="civilID" 
                                       #civilID="ngModel" [(ngModel)]="mainForm.civilID" onlyNumber
                                       [class.invalid]="civilID.invalid && civilID.touched">
                            </div>
                            <div class="col-lg-6 col-md-6 form-group">
                                <br />
                                <label>الولاية</label>*
                                <select required #gnAddressID="ngModel" name="gnAddressID" [(ngModel)]="mainForm.gnAddressID" class="form-control form-control-lg" >
                                    <option *ngFor="let option of gnAddressData" [value]="option.gnAddressID">{{ option.name }}</option>
                                </select>
                            </div>
                            <div class="col-lg-6 col-md-6 form-group">
                                <br />
                                <label>القرية</label>*
                                <input class="form-control form-control-lg" name="village" required
                                       #village="ngModel" [(ngModel)]="mainForm.village"
                                       [class.invalid]="village.invalid && village.touched">
                            </div>
                            <div class="col-lg-6 col-md-6 form-group">
                                <br />
                                <label>المدرسة</label>*
                                <select required #mnSchoolID="ngModel" name="mnSchoolID" [(ngModel)]="mainForm.mnSchoolID" class="form-control form-control-lg" >
                                    <option *ngFor="let option of mnSchoolData" [value]="option.mnSchoolID">{{ option.name }}</option>
                                </select>
                            </div>
                            <div class="col-lg-6 col-md-6 form-group">
                                <br />
                                <label>الصف</label>*
                                <select required #class="ngModel" name="class" value="الخامس" [(ngModel)]="mainForm.class" class="form-control form-control-lg">
                                    <option value="الخامس">الخامس</option>
                                    <option value="السادس">السادس</option>
                                    <option value="السابع">السابع</option>
                                    <option value="الثامن">الثامن</option>
                                    <option value="التاسع">التاسع</option>
                                    <option value="العاشر">العاشر</option>
                                    <option value="الحادي عشر">الحادي عشر</option>
                                    <option value="الثاني عشر">الثاني عشر</option>
                                </select>
                            </div>


                        </div>


                        <div class="col-lg-12 col-md-12">
                            <br />
                            <button class="default-btn" name="submit_button" [disabled]="(form.invalid || isDisabled)"><i class='bx bxs-paper-plane'></i>إرسال<span></span></button>
                        </div>
                    </form>

                </div>

                </div>
            </div>


        </div>
    </div>

