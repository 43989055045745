<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>أهداف المشروع</h2>
        </div>
    </div>
</div>


<section class="faq-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <h2>أهداف <span>المشروع</span></h2>
                    <div class="accordion" id="faqAccordion">
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    تنمية الشعور بأهمية القرآن الكريم وتلاوته وتجويده لدى الطلاب.
                                </button>
                            </div>

                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    تلاوة القرآن الكريم تلاوة مجودة خالية من أخطاء التشكيل.
                                </button>
                            </div>

                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    العمل على تحفيز الطلاب على تلاوة القرآن الكريم في المسجد والمدرسة.
                                </button>
                            </div>

                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    خدمة المجتمع في تخريج طلبة يتقنون تلاوة القرآن الكريم.
                                </button>
                            </div>

                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    العمل على نشر تعليم القاعدة النورانية.
                                </button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="faq-image wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                    <img src="assets/img/services-image/faq-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
