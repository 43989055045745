import { CategoryModule } from "../../masters/category/category.module";

export class FeedbackModule {
    wbFeedbackID: number = 0;
    name: string = '';
    phoneNo: string = '';
    email: string = '';
    gnCategoryID: number = 0;
    message: string = '';
    addDate: Date = new Date();
    type: string = '';
    notes: string = '';
    status: string = '';
   // categories: CategoryModule[];

    //category: CategoryModule = new CategoryModule();
}

export class VWFeedbackAndCategoryModule {
    wbFeedbackID: number = 0;
    name: string = '';
    phoneNo: string = '';
    email: string = '';
    gnCategoryID: number = 0;
    message: string = '';
    addDate: Date = new Date();
    type: string = '';
    notes: string = '';
    status: string = '';
    //categoryNameAr: string = '';
    //categoryNameEn: string = '';

}