export class RecitationLecturesAttendanceModule {
    mnRecitationLecturesAttendanceID: number = 0;
    mnRecitationLecturesID: number = 0;
    mnStudentYearID: number = 0;
    isAttend: boolean = false;
    notes: string = '';
}


export class ViewRecitationLecturesAttendanceModule {
    mnRecitationLecturesAttendanceID: number = 0;
    mnRecitationLecturesID: number = 0;
    courseTelawa: string = '';
    subject: string = '';
    date: Date = new Date();
    note: string = '';
    status: string = '';
    teacher: string = '';
    location: string = '';
    mnTeacherID: number = 0;
    mnGroupID: number = 0;
    mnLocationID: number = 0;
    academicYearName: string = '';
    mnStudentYearID: number = 0;
    mnSchoolID: number = 0;
    schoolName: string = '';
    mnStudentID: number = 0;
    studentName: string = '';
    isAttend: boolean = false;

}

export class RecitationLecturesAttendanceM {
    rowNumber: string = '';
    recitationLecturesAttendanceID: string = '';
    recitationLecturesID: string = '';
    studentYearID: string = '';
    name: string = '';
    isAttend: boolean = false;
    notes: string = '';
}

export interface RecitationLecturesAttendance {
    mnRecitationLecturesAttendanceID: number;
    mnRecitationLecturesID: number;
    mnStudentYearID: number;
    isAttend: boolean;
    notes: string;
}
