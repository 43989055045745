import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-challenge-competition',
  templateUrl: './challenge-competition.component.html',
  styleUrls: ['./challenge-competition.component.scss']
})
export class ChallengeCompetitionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
