export class ViewStudentYearModule {
    mnStudentYearID: number = 0;
    mnSchoolID: number = 0;
    schoolName: string = '';
    mnStudentID: number = 0;
    studentName: string = '';
    gnAddressID: number = 0;
    phone: string = '';
    otherPhone: string = '';
    village: string = '';
    civilID: string = '';
    mnLevelID: number = 0;
    levelName: string = '';
    mnGroupID: number = 0;
    groupName: string = '';
    mnBusID: number = 0;
    busName: string = '';
    mnAcademicYearID: number = 0;
    academicYearName: string = '';
    class: string = '';
    room: string = '';
    busPhone: string = '';
    mnLocationID: number = 0;
    locationName: string = '';

    note: string = '';
    latitude: string = '';
    longitude: string = '';
    dob: Date = new Date();
    accommodationDescription: string = '';
    gnTermsID: number = 0;
}
