import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/appviews/home/home.component';
import { SchoolsComponent } from './components/appviews/schools/schools.component';
import { RegisterTermsComponent } from './components/appviews/register/register-terms/register-terms.component';
import { RegisterDetailsComponent } from './components/appviews/register/register-details/register-details.component';
import { RegisterResultComponent } from './components/appviews/register/register-result/register-result.component';
import { StoryComponent } from './components/appviews/project_progress/story/story.component';
import { FounderComponent } from './components/appviews/project_progress/founder/founder.component';
import { ReasonComponent } from './components/appviews/project_progress/reason/reason.component';
import { TargetComponent } from './components/appviews/project_progress/target/target.component';
import { ResultComponent } from './components/appviews/project_progress/result/result.component';
import { ContactUsComponent } from './components/appviews/contact-us/contact-us.component';
import { Step1AboutComponent } from './components/appviews/parent_confirm/step1-about/step1-about.component';
import { Step2TermsComponent } from './components/appviews/parent_confirm/step2-terms/step2-terms.component';
import { Step3DetailsComponent } from './components/appviews/parent_confirm/step3-details/step3-details.component';
import { Step4LocationComponent } from './components/appviews/parent_confirm/step4-location/step4-location.component';
import { Step5DoneComponent } from './components/appviews/parent_confirm/step5-done/step5-done.component';
import { NotAvailableComponent } from './components/appviews/parent_confirm/not-available/not-available.component';
import { StudentDetailsComponent } from './components/appviews/student-details/student-details.component';
import { ChallengeCompetitionComponent } from './components/appviews/project_progress/challenge-competition/challenge-competition.component';
import { MethodologyComponent } from './components/appviews/project_progress/methodology/methodology.component';

const routes: Routes = [

    { path: '', component: HomeComponent },
    { path: 'schools', component: SchoolsComponent },
    { path: 'register_terms', component: RegisterTermsComponent },
    { path: 'register_details', component: RegisterDetailsComponent },
    { path: 'register_result', component: RegisterResultComponent },
    { path: 'story', component: StoryComponent },
    { path: 'founder', component: FounderComponent },
    { path: 'reason', component: ReasonComponent },
    { path: 'target', component: TargetComponent },
    { path: 'result', component: ResultComponent },
    { path: 'contact', component: ContactUsComponent },
    { path: 'confirm_step1', component: Step1AboutComponent },
    { path: 'confirm_step2', component: Step2TermsComponent },
    { path: 'confirm_step3', component: Step3DetailsComponent },
    { path: 'confirm_step4', component: Step4LocationComponent },
    { path: 'confirm_step5', component: Step5DoneComponent },
    { path: 'not_available', component: NotAvailableComponent },
    { path: 'student_details', component: StudentDetailsComponent },
    { path: 'challenge_competition', component: ChallengeCompetitionComponent },
    { path: 'methodology', component: MethodologyComponent }

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
