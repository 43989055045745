import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AcademicYearModule } from '../../../appshared/Main/academic-year/academic-year.module';
import { AcademicYearService } from '../../../appshared/Main/academic-year/academic-year.service';
import { RegistrationModule } from '../../../appshared/Main/registration/registration.module';
import { RegistrationService } from '../../../appshared/Main/registration/registration.service';
import { SchoolModule } from '../../../appshared/Main/school/school.module';
import { SchoolService } from '../../../appshared/Main/school/school.service';
import { AddressModule } from '../../../appshared/masters/address/address.module';
import { AddressService } from '../../../appshared/masters/address/address.service';
import * as moment from 'moment';

@Component({
  selector: 'app-register-details',
  templateUrl: './register-details.component.html',
  styleUrls: ['./register-details.component.scss']
})
export class RegisterDetailsComponent implements OnInit {
    public AcademicYearData: AcademicYearModule[];
    public mnSchoolData: SchoolModule[];
    public gnAddressData: AddressModule[];
    public isDisabled: boolean = false;
    bsValue = new Date();
    maxDate = new Date();
    mainForm = new RegistrationModule();

    constructor(
        public service: RegistrationService, private toastr: ToastrService, private router: Router,
        public servAcademicYear: AcademicYearService,
        public servSchool: SchoolService,
        public servAddress: AddressService) {
        this.maxDate.setDate(this.maxDate.getDate());
    }

    ngOnInit(): void {
        this.mainForm.status = "New";
        this.mainForm.class = "الخامس";

       // this.mainForm.dob = this.convetMoment(this.mainForm.dob);
        this.bsValue = this.mainForm.dob;


        this.getAllAcademicYear();
        this.getAllSchool();
        this.getAllAddress();
  }

    public getAllAcademicYear = () => {
        this.servAcademicYear.getService()
            .subscribe(res => {
                this.AcademicYearData = res as AcademicYearModule[];
                if (this.mainForm.mnAcademicYearID == 0) {
                    this.mainForm.mnAcademicYearID = this.AcademicYearData[0].mnAcademicYearID;
                }
            })
    }

    public getAllSchool = () => {
        this.servSchool.getService()
            .subscribe(res => {
                this.mnSchoolData = res as SchoolModule[];
            })
    }

    public getAllAddress = () => {
        this.servAddress.getServiceParent(1, 0, "Wilayat")
            .subscribe(res => {
                this.gnAddressData = res as AddressModule[];
                if (this.mainForm.gnAddressID == 0) {
                    this.mainForm.gnAddressID = this.gnAddressData[0].gnAddressID;
                }
            })
    }
    onSubmit(form: NgForm) {

        if (this.mainForm.mnSchoolID == 0 || this.mainForm.gnAddressID == 0) {

            this.toastr.warning('الرجاء تعبئة البيانات الأساسية', '');
        }
        else {

            this.isDisabled = true;
            this.insertRecord(form);
        }
    }


    serviceData2: RegistrationModule = new RegistrationModule();

    insertRecord(form: NgForm) {
        //this.loader.open();
        this.service.postService(this.mainForm).subscribe(
            res => {
                this.serviceData2 = res as RegistrationModule;
                this.toastr.success('تم الحفظ بنجاح', '');


                this.mainForm.mnRegistrationID = this.serviceData2.mnRegistrationID;
                this.SendNotificationDirect("registration-welcome");

                this.router.navigate(['register_result', { id: this.serviceData2.mnRegistrationID }]);

            },
            err => {
                this.toastr.error(err, '');
                this.isDisabled = false;
            }
        );
    }


    SendNotificationDirect(type: string) {
        this.service.getServiceSendMessage(this.mainForm.mnRegistrationID, type).subscribe(
            res => {
            },
            err => {
            }
        );
    }


    convetMoment(startDate: Date): Date {
        let momentToDate = moment(startDate).toDate();
        return new Date(Date.UTC(momentToDate.getFullYear(), momentToDate.getMonth(), momentToDate.getDate(), momentToDate.getHours(), momentToDate.getMinutes(), momentToDate.getSeconds()));
    }

}
