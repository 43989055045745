<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>اعتماد الطالب</h2>
            <p>الخطوة الأولى: نبذة عن المشروع</p>
        </div>
    </div>
</div>

<div class="service-details ptb-100">
    <div class="container">
        <div class="row align-items-center">




            <div class="">
                <div class="content left-content">
                    <h4>نبذة عن المشروع:</h4>
                    <p>
                        هو مشروع يقوم بتدريس القرآن تلاوة وتجويدا وتحفيظا وفق خطة مرسومة وعمل منظم طوال العام الدراسي من شهر ٩ إلى شهر ٤ من خلال عمل حلقات تعليمية متواصلة بواقع حلقة واحدة أسبوعيا.

                    </p>


                    <br />
                    <br />
                    <p style="font-weight:bold">
                        للمدارس الآتية:

                    </p>
                    <div class="row">
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>	مدرسة المبرد</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>  مدرسة الإمام غسان بن عبدالله</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>  مدرسة الصبيخي</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>   مدرسة الأحنف بن قيس</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>   مدرسة موسى بن أبي جابر</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>   مدرسة عبدالله بن العباس</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>    مدرسة ابن دريد</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>    مدرسة الحسن بن علي</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>   مدرسة جميل بن خميس</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>     مدرسة جابر بن عبدالله</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>      معهد العلوم الإسلامية</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>  مدرسة أبي طلحة الأنصاري</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>     مدرسة المسعى</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>     مدرسة الإمام راشد اليحمدي</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>    مدرسة عثمان بن مظعون</b>
                            </div>
                        </div>
                    </div>


                    <p>

                        الحلقة ستكون يوم السبت من الساعة ١٠ إلى الساعة ١٢ صباحا في جامع الأنوار خلف بلدية السويق مع توفير وسيلة النقل.
                    </p>
                    <br />
                    <br />
                    <p style="font-weight:bold">
                        للمدارس الآتية:


                    </p>

                    <div class="row">

                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>     مدرسة السويق</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>     مدرسة الإمام الوارث بن كعب</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>   مدرسة ثابت بن كعب</b>
                            </div>
                        </div>

                    </div>


                    <p>


                        الحلقة ستكون يوم الأحد بين المغرب والعشاء في جامع الأبرار بالقرب من مدرسة حليمة مع توفير وسيلة النقل.


                    </p>


                    <br />

                </div>
            </div>

            <div class="btn-box">
                <button type="button" class="default-btn m-4" [hidden]="!isActive" (click)="onClickBT()">التالي</button>

           
                <button type="button" class="default-btn m-4" style="background-color: #ff612f; " [hidden]="!isActive" (click)="onReject()">رفض</button>

            </div>

            </div>
        </div>
</div>
