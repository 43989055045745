<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>المدارس المشاركة في المشروع</h2>
        </div>
    </div>
</div>

<div class="service-details ptb-100">
    <div class="container">
        <div class="row align-items-center">
      

            <div class="">
                <div class="content left-content">
                    <h6>يضم المشروع 17 مدرسة من مدارس ولاية السويق وهي كالآتي:</h6>
                    <br />
                    <div class="row">
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>	مدرسة المبرد</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>  مدرسة الإمام غسان بن عبدالله</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>  مدرسة الصبيخي</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>   مدرسة الأحنف بن قيس</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>   مدرسة موسى بن أبي جابر</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>   مدرسة عبدالله بن العباس</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>    مدرسة ابن دريد</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>    مدرسة الحسن بن علي</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>   مدرسة جميل بن خميس</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>     مدرسة جابر بن عبدالله</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>     مدرسة السويق</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>     مدرسة الإمام الوارث بن كعب</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>   مدرسة ثابت بن كعب</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>      معهد العلوم الإسلامية</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>  مدرسة أبي طلحة الأنصاري</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>     مدرسة المسعى</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>     مدرسة عثمان بن مظعون</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
