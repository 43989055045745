<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>نبذة عن مؤسس المشروع</h2>
        </div>
    </div>
</div>

<div class="service-details ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="services-image wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                <div class="image">
                    <img src="assets/img/services-image/service1.png" alt="image">
                </div>
            </div>

            <div class="services-content it-service-content">
                <div class="content left-content">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h2>عن المؤسس</h2>
                    <br />
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.</p>-->
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>  سيف بن علي بن ناصر الكيومي.
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> الإجازة العالية من معهد العلوم الشرعية سنة 1430هـ / 2009م.
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>واعظ ولاية السويق بوزارة الأوقاف والشؤون الدينية.
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> المشرف العام على ( مشروع تلاوة القرآن الكريم ) في ولاية السويق ونيابة الحوقين.
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> مؤسس المشروع لتلبية الحاجة المجتمعية لتخريج قراء وحفظه القرآن الكريم.
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
