<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>التسجيل في المشروع</h2>
            <p>الخطوة الأولى شروط التسجيل</p>
        </div>
    </div>
</div>

<div class="service-details ptb-100">
    <div class="container">
        <div class="row align-items-center">




            <div class="">
                <div class="content left-content">
                    <h4>شروط التسجيل:</h4>
                    <p>طلاب المشروع: ويقصد بهم الطلاب من الصف الخامس حتى العاشر والذين هم من طلاب أحد المدارس الآتية:</p>
                    <br />
                    <div class="row">
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>	مدرسة المبرد</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>  مدرسة الإمام غسان بن عبدالله</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>  مدرسة الصبيخي</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>   مدرسة الأحنف بن قيس</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>   مدرسة موسى بن أبي جابر</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>   مدرسة عبدالله بن العباس</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>    مدرسة ابن دريد</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>    مدرسة الحسن بن علي</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>   مدرسة جميل بن خميس</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>     مدرسة جابر بن عبدالله</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>     مدرسة السويق</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>     مدرسة الإمام الوارث بن كعب</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>   مدرسة ثابت بن كعب</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>      معهد العلوم الإسلامية</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>  مدرسة أبي طلحة الأنصاري</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>     مدرسة المسعى</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>     مدرسة الإمام راشد اليحمدي</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-2">
                            <div class="feature-box  tx-center">
                                <b>     مدرسة عثمان بن مظعون</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h6>مع توفر الشروط الآتية للتسجيل:</h6>
            <ul>
                <li>	أن يكون الطالب مقيداً في السنة الدراسية القادمة في الصفوف (5-6-7-8-9-10) في مدارس ولاية السويق فقط .</li>
                <li>	يشترط للطالب أن يكون حافظاً لجزء عم كاملا، ( سيتم إجراء اختبار كتابي ).</li>
                <li>	التواصل المستمر والمواظبة الحضورية في الحلقات القرآنية طوال العام الدراسي.</li>
                <li>	المشاركة الفعالة في تلاوة وحفظ القرآن الكريم مع إتقانه عن طريق الاختبارات الكتابية والشفوية.</li>
            </ul>
            <p>
                ينتهي موعد التسجيل يوم السبت ١٠ من ربيع الأول ١٤٤٦ هـ
                الموافق له ١٤ من سبتمبر ٢٠٢٤م
            </p>


            <button type="submit" class="default-btn" (click)="onClickBT()">أوافق على الشروط</button>


        </div>
    </div>
</div>
