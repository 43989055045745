import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-step5-done',
  templateUrl: './step5-done.component.html',
  styleUrls: ['./step5-done.component.scss']
})
export class Step5DoneComponent implements OnInit {

    public thisID: number = 0;
    private sub: any;
    constructor(private route: ActivatedRoute) { }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.thisID = +params['id'];
        });
    }

}
