export class AddressIPsModule {
    wbAddressIPsID: number = 0;
    ip: string = '';
    countryCode: string = '';
    countryName: string = '';
    regionCode: string = '';
    regionName: string = '';
    city: string = '';
    zipCode: string = '';
    latitude: string = '';
    longitude: string = '';
    addDate: Date = new Date();
    totalOnlineUsers: number = 0;
}
