import { Component, OnInit } from '@angular/core';
import { RegistrationService } from '../../../appshared/Main/registration/registration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationModule } from '../../../appshared/Main/registration/registration.module';
import { HttpErrorResponse } from '@angular/common/http';
import { TermsModule } from '../../../appshared/masters/terms/terms.module';
import { TermsService } from '../../../appshared/masters/terms/terms.service';

@Component({
  selector: 'app-step2-terms',
  templateUrl: './step2-terms.component.html',
  styleUrls: ['./step2-terms.component.scss']
})
export class Step2TermsComponent implements OnInit {
    isActive: boolean = false;
    public rid: number = 0;
    public tid: number = 0;
    public phone: string = '';
    public PageContentHTML: string = '';
    private sub: any;
    constructor(public service: TermsService, private route: ActivatedRoute, private router: Router) { }

    ngOnInit(): void {

        this.sub = this.route.params.subscribe(params => {
            this.rid = +params['rid'];
            this.tid = +params['tid'];
            this.phone = params['phone'];
            this.checkAvailbale();
        });
    }
    checkAvailbale() {

        this.service.getServiceId(this.tid)
            .subscribe(res => {
                var dataT:any = [] 
                dataT = res as TermsModule[];


                this.PageContentHTML = dataT.terms;

                this.isActive = true;

            },
                (error: HttpErrorResponse) => {

                    this.router.navigate(['not_available']);

                });
    }


    onClickBT() {

        this.router.navigate(['confirm_step3', { rid: this.rid, phone: this.phone, tid: this.tid }]);
    }

}
