import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MemorizationLectureModule, ViewMemorizationLectureModule } from './memorization-lecture.module';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemorizationLectureService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }


    getService(): Observable<MemorizationLectureModule[]> {
        return this.http.get<MemorizationLectureModule[]>(environment.apiURL + 'Main/MNMemorizationLecture/');
    }


    getServiceId(id: number): Observable<MemorizationLectureModule[]> {
        return this.http.get<MemorizationLectureModule[]>(environment.apiURL + 'Main/MNMemorizationLecture/' + id);
    }

    getServiceStudentId(StudentId: number, types: string): Observable<ViewMemorizationLectureModule[]> {
        return this.http.get<ViewMemorizationLectureModule[]>(environment.apiURL + 'View/ViewMemorizationLecture/SearchStudent/?StudentID=' + StudentId + '&types=' + types);
    }

    getServiceLastStudentId(StudentId: number, types: string): Observable<ViewMemorizationLectureModule[]> {
        return this.http.get<ViewMemorizationLectureModule[]>(environment.apiURL + 'View/ViewMemorizationLecture/LastStudent/?StudentID=' + StudentId + '&types=' + types);
    }
}
