export class StudentYearModule {
    mnStudentYearID: number = 0;
    mnSchoolID: number = 0;
    mnStudentID: number = 0;
    mnLevelID: number = 0;
    mnGroupID: number = 0;
    mnBusID: number = 0;
    mnAcademicYearID: number = 0;
    class: string = '';
    room: string = '';
    mnLocationID: number = 0;
}
