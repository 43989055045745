import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { SchoolModule } from './school.module';


@Injectable({
  providedIn: 'root'
})
export class SchoolService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }


    getService(): Observable<SchoolModule[]> {
        return this.http.get<SchoolModule[]>(environment.apiURL + 'Main/MNSchool/');
    }
}
