import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService } from '../../../appshared/Main/registration/registration.service';
import { HttpErrorResponse } from '@angular/common/http';
import { RegistrationModule } from '../../../appshared/Main/registration/registration.module';
import { StudentService } from '../../../appshared/Main/student/student.service';
import { StudentYearService } from '../../../appshared/Main/student-year/student-year.service';

@Component({
  selector: 'app-step1-about',
  templateUrl: './step1-about.component.html',
  styleUrls: ['./step1-about.component.scss']
})
export class Step1AboutComponent implements OnInit {

    mainForm = new RegistrationModule();

    isActive: boolean = false;
    public rid: number = 0;
    public tid: number = 0;
    public phone: string = '';
    private sub: any;
    constructor(public service: RegistrationService, private route: ActivatedRoute, private router: Router,
        public servStudent: StudentService,
        public servStudentYear: StudentYearService,) { }

    ngOnInit(): void {

         this.sub = this.route.params.subscribe(params => {
             this.rid = +params['rid'];
             this.phone = params['phone'];
             this.checkAvailbale();
        });
  }
    checkAvailbale() {

        this.service.getServiceFindParent(this.phone, this.rid)
            .subscribe(res => {

                var dataReg = res as RegistrationModule[];
                this.mainForm = dataReg[0];

                this.tid = dataReg[0].gnTermsID;
                this.isActive = true;

            },
                (error: HttpErrorResponse) => {

                    this.router.navigate(['not_available']);

                });
    }


    onClickBT() {

        this.router.navigate(['confirm_step2', { rid: this.rid, phone: this.phone, tid: this.tid }]);
    }

    onReject() {

        this.mainForm.status = "Accepted";
        this.service.putService(this.mainForm).subscribe(
            res => {
                this.router.navigate(['not_available']);
            },
            err => {
                console.log(err);
                this.router.navigate(['not_available']);
            }
        );
    }
}
