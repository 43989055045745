<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>قصة المشروع</h2>
        </div>
    </div>
</div>


<div class="service-details ptb-100">
    <div class="container">
        <div class="service-details-image">
            <img src="assets/img/services-image/single-service2.jpg" alt="image">
        </div>
        <div class="service-details-content">
            <h3>بداية الفكرة</h3>

            <p>
                في العام الدراسي 1430 – 1431ه/ 2009 – 2010م وبعد تخرجي من معهد العلوم الشرعية ( كلية العلوم الشرعية ) بحمد الله وتوفيقه مباشرة رأيت حاجة ملحة في إنشاء برنامج قرآني تلاوة وحفظا على شكل حلقات متواصلة كما رأيت ذلك ورأى غيري في دول أخرى عيانا ومشاهدة عن قرب والتطور الحاصل في تلك الدول تخطيطا وتنظيما وعملا وجهدا مباركا فيه، ففكرت في طريقة لعلاج المشكلة الأكبر في المجتمع المحلي ( في ذلك الوقت ) بما يمثله من البيت والمسجد والمدرسة ورأيت حاجة ملحة لاكتشاف طريقة عاجلة لهذه المشكلة، فتبادرت إلي أفكار كثيرة وأطر عملية لعمل شيء في هذا الجانب.
            </p>


            <h4>المدرسة الأولى</h4>
            <p>
                كنت في زيارة لمدرسة ثابت بن كعب للتعليم الأساسي في ولاية السويق في منتصف الفصل الأول للعام الدراسي 1430 – 1431ه/ 2009 – 2010م للسلام على الأساتذة الكرام فوجدت ترحيبا كبيرا مما أدخل السرور على قلبي ورأيت عن قرب طلاب المدرسة وأحوالهم فيها فكشف لي الكثير من الوجوه المشرقة والبوادر الخيرة في مستوياتهم.
            </p>
            <p>
                رجعت إلى البيت وأنا أفكر فيما وجدته في المدرسة من الترحيب والإيجابية الكبيرة في مستوى الطلاب فتبادرت إليَّ فكرة عملية أترجمها في المدرسة في موضوع القرآن الكريم.
            </p>

            <p>

                وبعد تفكير كثير استخلصت فكرة تدريس القرآن الكريم في وقت الفسحة لاستغلالها في رفع مستوى الطلاب في تلاوة وتجويد القرآن الكريم.

            </p>
                <div class="service-details-info" dir="ltr">
                    <div class="single-info-box">
                        <h4>مؤسس المشروع</h4>
                        <span>سيف بن علي بن ناصر الكيومي</span>
                    </div>

                </div>
        </div>
    </div>
</div>


