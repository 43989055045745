import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LevelModule } from './level.module';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LevelService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    getLevel(): Observable<LevelModule[]> {
        return this.http.get<LevelModule[]>(environment.apiURL + 'Main/MNLevel/');
    }

    getServiceId(id: number): Observable<LevelModule[]> {
        return this.http.get<LevelModule[]>(environment.apiURL + 'Main/MNLevel/' + id);
    }
}
