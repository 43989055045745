<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>اعتماد الطالب</h2>
            <p>الخطوة الثانية: الشروط</p>
        </div>
    </div>
</div>

<div class="service-details ptb-100">
    <div class="container">
        <div class="row align-items-center">




            <div class="">
                <div class="content left-content">
                    <div [innerHtml]="PageContentHTML"></div>



                </div>
            </div>
           


            <button type="submit" class="default-btn" [hidden]="!isActive" (click)="onClickBT()">أوافق على الشروط</button>


        </div>
    </div>
</div>
