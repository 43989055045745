import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ViewStudentYearModule } from './view-student-year.module';
import { environment } from '../../../../../environments/environment';
import { Filter } from '../../Main/academic-year/academic-year.module';

@Injectable({
  providedIn: 'root'
})
export class ViewStudentYearService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    getService(): Observable<ViewStudentYearModule[]> {
        return this.http.get<ViewStudentYearModule[]>(environment.apiURL + 'View/ViewMNStudentYear/');
    }

    search: string = "";
    getServiceFilter(filters: Filter[], mnAcademicYearID: number): Observable<ViewStudentYearModule[]> {
        this.search = "";

        for (var i = 0; i < filters.length; i++) {
            if (this.search == "") {
                this.search = "?"
            }
            else {
                this.search += "&";
            }
            this.search += filters[i].type + "=" + filters[i].valueID;
        }
        if (this.search == "") {
            this.search = "?mnAcademicYearID=" + mnAcademicYearID + "&topNo=200";
        }
        else {
            this.search += "&mnAcademicYearID=" + mnAcademicYearID + "&topNo=0";
        }
        return this.http.get<ViewStudentYearModule[]>(environment.apiURL + 'View/ViewMNStudentYear/viewFilter/' + this.search);
    }

    getServiceCompletedFilter(filters: Filter[], mnAcademicYearID: number): Observable<ViewStudentYearModule[]> {
        this.search = "";

        for (var i = 0; i < filters.length; i++) {
            if (this.search == "") {
                this.search = "?"
            }
            else {
                this.search += "&";
            }
            this.search += filters[i].type + "=" + filters[i].valueID;
        }
        if (this.search == "") {
            this.search = "?mnAcademicYearID=" + mnAcademicYearID + "&topNo=200";
        }
        else {
            this.search += "&mnAcademicYearID=" + mnAcademicYearID + "&topNo=0";
        }
        return this.http.get<ViewStudentYearModule[]>(environment.apiURL + 'View/ViewMNStudentYear/viewCompleted/' + this.search);
    }

    getServiceNoBus(filters: Filter[], mnAcademicYearID: number): Observable<ViewStudentYearModule[]> {
        this.search = "";

        for (var i = 0; i < filters.length; i++) {
            if (this.search == "") {
                this.search = "?"
            }
            else {
                this.search += "&";
            }
            this.search += filters[i].type + "=" + filters[i].valueID;
        }
        if (this.search == "") {
            this.search = "?mnAcademicYearID=" + mnAcademicYearID + "&topNo=200";
        }
        else {
            this.search += "&mnAcademicYearID=" + mnAcademicYearID + "&topNo=0";
        }
        return this.http.get<ViewStudentYearModule[]>(environment.apiURL + 'View/ViewMNStudentYear/viewNoBus/' + this.search);
    }

    getServiceNoLevel(filters: Filter[], mnAcademicYearID: number): Observable<ViewStudentYearModule[]> {
        this.search = "";

        for (var i = 0; i < filters.length; i++) {
            if (this.search == "") {
                this.search = "?"
            }
            else {
                this.search += "&";
            }
            this.search += filters[i].type + "=" + filters[i].valueID;
        }
        if (this.search == "") {
            this.search = "?mnAcademicYearID=" + mnAcademicYearID + "&topNo=200";
        }
        else {
            this.search += "&mnAcademicYearID=" + mnAcademicYearID + "&topNo=0";
        }
        return this.http.get<ViewStudentYearModule[]>(environment.apiURL + 'View/ViewMNStudentYear/viewNoLevel/' + this.search);
    }

    getServiceNoGroup(filters: Filter[], mnAcademicYearID: number): Observable<ViewStudentYearModule[]> {
        this.search = "";

        for (var i = 0; i < filters.length; i++) {
            if (this.search == "") {
                this.search = "?"
            }
            else {
                this.search += "&";
            }
            this.search += filters[i].type + "=" + filters[i].valueID;
        }
        if (this.search == "") {
            this.search = "?mnAcademicYearID=" + mnAcademicYearID + "&topNo=200";
        }
        else {
            this.search += "&mnAcademicYearID=" + mnAcademicYearID + "&topNo=0";
        }
        return this.http.get<ViewStudentYearModule[]>(environment.apiURL + 'View/ViewMNStudentYear/viewNoGroup/' + this.search);
    }

    getServiceId(id: number): Observable<ViewStudentYearModule[]> {
        return this.http.get<ViewStudentYearModule[]>(environment.apiURL + 'View/ViewMNStudentYear/' + id);
    }
    
    generatePDFReportFilter(status: string, fileType: string) {

        return this.http.get(environment.apiURL + 'View/ViewMNStudentYear/generateReport/?status=' + status + '&fileType=' + fileType, { observe: 'response', responseType: 'blob' });
    }
}
