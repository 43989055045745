export class RegistrationModule {
    mnRegistrationID: number = 0;
    gnAddressID: number = 0;
    gnTermsID: number = 0;
    mnSchoolID: number = 0;
    mnAcademicYearID: number = 0;
    name: string = '';
    phoneNo: string = '';
    otherPhoneNo: string = '';
    dob: Date = new Date();
    civilID: string = '';
    village: string = '';
    class: string = '';
    status: string = '';
    remarks: string = '';
}
