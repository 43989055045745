import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocationModule } from './location.module';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };
    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    getServeAcademicYear(academicYearID: number): Observable<LocationModule[]> {
        return this.http.get<LocationModule[]>(environment.apiURL + 'Main/MNLocation/ViewAcademicYear/?academicYearID=' + academicYearID);
    }


    getYear(yearID: number): Observable<LocationModule[]> {
        return this.http.get<LocationModule[]>(environment.apiURL + 'Main/MNLocation/SearchYearID/?YearID=' + yearID);
    }

    getLocation(): Observable<LocationModule[]> {
        return this.http.get<LocationModule[]>(environment.apiURL + 'Main/MNLocation/');
    }

    getServiceId(id: number): Observable<LocationModule[]> {
        return this.http.get<LocationModule[]>(environment.apiURL + 'Main/MNLocation/' + id);
    }
}
