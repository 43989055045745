import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { GroupModule } from './group.module';


@Injectable({
  providedIn: 'root'
})
export class GroupService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    getGroup(): Observable<GroupModule[]> {
        return this.http.get<GroupModule[]>(environment.apiURL + 'Main/MNGroup/');
    }


    getYear(yearID: number): Observable<GroupModule[]> {
        return this.http.get<GroupModule[]>(environment.apiURL + 'Main/MNGroup/SearchYearID/?YearID=' + yearID);
    }


    getServeLocation(locationID: number): Observable<GroupModule[]> {
        return this.http.get<GroupModule[]>(environment.apiURL + 'Main/MNGroup/ViewLocaion/?locationID=' + locationID);
    }

    getServiceId(id: number): Observable<GroupModule[]> {
        return this.http.get<GroupModule[]>(environment.apiURL + 'Main/MNGroup/' + id);
    }


}
