import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
declare let $: any;
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AddressIPsService } from './components/appshared/webTools/address-ips/address-ips.service';
import { AddressIPsModule } from './components/appshared/webTools/address-ips/address-ips.module';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;

    constructor(public service: AddressIPsService,private router: Router, private http: HttpClient, private toastr: ToastrService) {
        this.getIPAddress();
    }

    ngOnInit(){
        this.recallJsFuntions();
    }


getIPAddress()
{
    
    this.http.get("https://geolocation-db.com/json/").subscribe((res: any) => {
        this.service.formData.ip = res.IPv4;
        this.service.formData.countryName = res.country_name;
        this.service.formData.countryCode = res.country_code;
        this.service.postService().subscribe(
            data => {
             //   this.toastr.success('مرحيا بك في تلاوة', res.IPv4);

            },
            err => {
                this.toastr.warning('مرحبا بك في تلاوة', 'خطأ في الأتصال');
            }
        );


    });


}

    recallJsFuntions() {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {
                $('.preloader-area').fadeIn('slow');
            }
        });
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            $.getScript('../assets/js/conversation.js');
            $('.preloader-area').fadeOut('slow');
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
}
