<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>منهج المشروع</h2>
        </div>
    </div>
</div>


<div class="service-details ptb-100">
    <div class="container">
      
        <div class="service-details-content">

            <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        النون والميم المشددتان | 1 (سلسلة أحكام التلاوة والتجويد)
                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/TcrnmAUgOF8?si=eV04BDz3xErPgTly" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        لإظهار الحلقي | 2 "أحكام النون الساكنة والتنوين" (سلسلة أحكام التلاوة والتجويد)
                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Fkd0NIjUkQs?si=fDo6m3pzu8ElzY6I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        الإدغام | 3 "أحكام النون الساكنة والتنوين" (سلسلة أحكام التلاوة والتجويد)
                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/pbDXg2kBYN8?si=xnR4tziijPjmWVXy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>


                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        الإقلاب | 4 "أحكام النون الساكنة والتنوين" (سلسة أحكام التلاوة والتجويد )

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/5EhdiokFlbs?si=28DNFWOKW6cyMdPU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                


                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        الإخفاء | 5 "أحكام النون الساكنة والتنوين" (سلسة أحكام التلاوة والتجويد)


                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/RNXcTbJsWo4?si=-L0KX2Jgweut_ujW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                </div>





                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        الإخفاء الشفوي | 6 "أحكام الميم الساكنة" (سلسلة أحكام التلاوة والتجويد)

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/MeLqAU9iJpw?si=67hzPzxAUikKannb" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        الإدغام الشفوي والإظهار الشفوي | 7 "أحكام الميم الساكنة" (سلسلة أحكام التلاوة والتجويد)

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/oD4um5dc7Cw?si=AKxs6Q87dUpehgit" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        المد الطبيعي | 8 " أحكام المد" (سلسة أحكام التلاوة والتجويد)

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/gTWNPNT5Q0I?si=D0bQs8ptat08cyMQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        المد الواجب المتصل + المد الجائز المنفصل | 9 "أحكام المد" (سلسلة أحكام التلاوة)

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/fI28o9WhYxA?si=CFQj2CzWbGJez9u_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        المد اللازم | 10 "أحكام المد" (سلسلة أحكام التلاوة)

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/raRX4lLdJUg?si=gEK5GpRAPsSIqPE_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        المد العارض للسكون | 11 "أحكام المد" (سلسة أحكام التلاوة والتجويد)

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/7uteVyNYWZ8?si=2VbBW7G7U_3Nu8dy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        مد الصلة "الصغرى والكبرى" | 12 "أحكام المد" (سلسة أحكام التلاوة والتجويد)

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/I-5nnBlMp0U?si=u4s5ir6pA_KqjKuy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        التفخيم والترقيق (الجزء الأول) | 13 "(سلسة أحكام التلاوة والتجويد)

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/CBOiw1VdPWs?si=rBOIERlrDY6x-Mnn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        التفخيم والترقيق (الجزء الثاني) | 14 | حالات الراء ، الألف ، اللام (سلسة أحكام التلاوة والتجويد)

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/OrkCPaAX-po?si=KJkFPPQEYmLFEZY8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        حكم القلقلة | 15 | (سلسة أحكام التلاوة والتجويد)

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/3rWxKRZ9Q0Y?si=WCvyC6syGmWMvRC8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        مراتب القلقة من حلقة 28 (الاتقان لتلاوة القران)


                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/cc_sc-Zp12I?si=HElUOuxvdpRfrkee" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        اللحن الجلي واللحن الخفي

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/HiZf8DLW-KI?si=FWDPqZRd8-bN_UJN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        تعرّف على القرآن الكريم

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Kr9RCoMWph0?si=kxH5UqRSpFWl50VQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        اولا : مخرج الجوف ( خلاء الحلق والفم ) - برنامج الاتقان لتلاوة القران .

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/GMFVSOef0Zs?si=mog0rzOGYc9kuGh_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        ثانيا : الحلق ( الهمزة والهاء ، العين والحاء ، الغين والخاء) من برنامج الاتقان لتلاوة القران .

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/xqQkxf6XMQU?si=n6qT6iBwMfK27p4X" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        ثالثا : مخارج اللسان ج1 :وفيه : (1)- القاف (2)- الكاف (3)- الجيم والشين والياء-- برنامج الاتقان

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/x34n0Q4UTS0?si=ajdWi0NiAUO80vjg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        ثالثا : مخارج اللسان (ج2 ) وفيه حرف (الضاد) وما يتعلق به من تنبيهات - برنامج الاتقان لتلاوة القران

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/SPIGcLthkw8?si=1vaCTZvQbVRHClKg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        ثالثا : مخارج اللسان ج ( 3 ) وفيه مخرج اللام والنون - برنامج الاتقان لتلاوة القران

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/XjFhuOKf6R8?si=Y5bsrPm3jUXNME_U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        ثالثا : مخارج اللسان ج ( 4) وفيه مخرج الراء مع صفة التكرير - برنامج الاتقان لتلاوة القران

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/RSlTUI2LVrA?si=gipzlvOaGfOrmNRH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        ثالثا : مخارج اللسان ج (5) وفيه حروف( ط- د- ت ) وحروف الصفير ( ص-س-ز) - الاتقان لتلاوة القران

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/RtmaHJJRIRA?si=Dj6XoBhrXqj61vzy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        ثالثا: مخارج اللسان ج (6) والاخير وفيه حروف ( الظاء والذال والثاء ) برنامج الاتقان لتلاوة القران

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/ENNWHEybzrs?si=nTDSY1ZutfaTFIFZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        رابعا : مخرج الشفتين وفيه حروف ( الفاء - الباء - الواو- الميم ) برنامج الاتقان لتلاوة القران

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/oiC2zg0Zpek?si=ViUdMqs7TsaLtWly" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        خامسا : مخرج الخيشوم (المخرج الاخيرمن من مخارج الحروف) - برنامج الاتقان لتلاوة القران

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/FUbBrsqy4vI?si=jFMvZnsuRrplGc5X" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        مقدمة في صفات الحروف العربية من حلقة رقم 11 ( الاتقان لتلاوة القران)

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/QzJ_hPejHHo?si=kEyqzEJ72QUTnIFl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        الصفة 2 من الصفات المتضادة : (الشدة #الرخاوة #البينية ) برنامج الاتقان لتلاوة القران ح12

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/I1EvSudkJWI?si=W0mR2-y79L-TlkYc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        الصفة 2 من الصفات المتضادة : (1- الشدة ) برنامج الاتقان لتلاوة القران حلقة 12

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/HU4eWHm5OAs?si=OyTT0ORtBvs1OLeT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        الصفة 2 من الصفات المتضادة :( 2 #الرخاوة 3 #البينية ) الاتقان لتلاوة القران حلقة 12

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/IF8nm-Jw0Os?si=RYLRkZoKdEnDoZCp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        تلخيص لصفتي الهمس والشدة وضدهما الجهر والرخاوة من حلقة 13 الاتقان لتلاوة القران

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/58mxvEj1f6g?si=uc3NSgGM7aO0QgBj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        الصفة 3 من الصفات المتضادة : (الاستعلاء#الاستفال ) برنامج الاتقان لتلاوة القران حلقة 14


                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/7dBw-SL3OkM?si=_lh_txR3pG6JmIlo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        الصفة 4 من الصفات المتضادة : (الاطباق # الانفتاح )برنامج الاتقان لتلاوة القران حلقة 14


                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/TPr3FKbSJYA?si=EjUkoX6Hsna69j7g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>

                        الصفة 5 من الصفات المتضادة : (الإذلاق # الإصمات) برنامج الاتقان لتلاوة القران ح 14

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/yVoCsdKO6_4?si=TUxuy1lWqJGEawku" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>

                        1- صفة الصفير.. برنامج الاتقان لتلاوة القران من (ح 15)

                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/fAiaDWaxapg?si=3mfj1CNPdESR21VY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        2- صفة القلقة ( برنامج الاتقان لتلاوة القران ) من ح15


                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/E0rXs8R8cak?si=VtqYPKJZKkda2bT0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        4 - صفة الانحراف: صفة لحرفي ( ل) ،( ر ) - برنامج الاتقان لتلاوة القران ح 17


                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/TtYuEUtdbGo?si=uq8BZ7kto7BfY4Cl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        5- صفة التكرير : في حرف (الراء) فقط من برنامج الاتقان لتلاوة القران من ح17


                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/RTFW856EOMc?si=ZhIUcB9a9-Hd1JgP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        6- صفة التفشي : (حرف الشين ) من ح 18 برنامج الاتقان لتلاوة القران
                    </h5>

                    <iframe width="560" height="315" src="https://www.youtube.com/embed/YZ8eSHToPcQ?si=7PO6MHzpo9ywmc7f" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        6- صفة التفشي : (حرف الشين ) من ح 18 برنامج الاتقان لتلاوة القران


                    </h5>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/YZ8eSHToPcQ?si=FOk5833D19wT2NYd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <h5>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/X-JEl4RL3Qs?si=vCS2qudklxGrZIFp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </h5>
                    8 - صفة الغنة : (:ن، م )برنامج الاتقان لتلاوة القران ح 18

                </div>


                

            </div>

            </div>
    </div>
</div>


