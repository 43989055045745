import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { AddressModule } from './address.module';

@Injectable({
    providedIn: 'root'
})
export class AddressService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }


    getService(ComID: number): Observable<AddressModule[]> {
        return this.http.get<AddressModule[]>(environment.apiURL + 'General/GNAddress/search/' + ComID);
    }

    getServiceParent(ComID: number,Parent: number, types: string): Observable<AddressModule[]> {
        return this.http.get<AddressModule[]>(environment.apiURL + 'General/GNAddress/searchParent/' + Parent + '&' + types);
    }

    getServiceType(type: string): Observable<AddressModule[]> {
        return this.http.get<AddressModule[]>(environment.apiURL + 'General/GNAddress/searchType/' + type);
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'General/GNAddress/' + id).toPromise();
    }

    formData: AddressModule = new AddressModule();
    list: AddressModule[];

    postService() {
        return this.http.post(environment.apiURL + 'General/GNAddress/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'General/GNAddress/' + this.formData.gnAddressID, this.formData);
    }
}
