<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>مسابقة التحدي لتلاوة القرآن الكريم</h2>
        </div>
    </div>
</div>


<div class="service-details ptb-100">
    <div class="container">
        <div class="service-details-image">
            <img src="assets/img/services-image/single-service3.jpg" alt="image">
        </div>
        <div class="service-details-content">
            <h3>المسابقة الأولى  :</h3>

            <p>
                جاءت فكرة هذه المسابقة لزيادة التنافس والندية بين الطلاب المشاركين وهي عبارة عن إقامة مسابقة بين عشرة طلاب في مدرستين بشرط أن يكونوا في نفس المستوى القرآني.
            </p>  <p>
                وكانت بداية الانطلاقة في السنة الثانية للمشروع بضيافة مدرسة عثمان بن مظعون بنيابة الحوقين بين مدرستي السويق وعثمان بن مظعون للتعليم الأساسي وحققت المسابقة في شمعتها الأولى نجاحا كبيرا مما جعل ثمانية طلاب منهم يحققون العلامة الكاملة.
            </p>


            <h3>   المسابقة الثانية :</h3>
            <p>
                بعد نجاح الفكرة في محطتها الأولى تتابعت المسابقة فكانت المحطة الثانية بين مدرستي الإمام الوارث بن كعب وعثمان بن مظعون في ضيافة مدرسة الإمام الوارث ووصل التنافس بين الطلاب إلى مرحلة مخارج وصفات الحروف مما أوصلهم إلى قمة التركيز والندية بينهم.
            </p>

            <h3>   المسابقة الثالثة :</h3>
            <p>
                وتواصل نجاح فكرة هذه المسابقة حيث دخلت مدرسة ثابت بن كعب في خط المنافسة بقوة من خلال كوكبة من الطلاب المميزين فتنافسوا في المحطة الثالثة مع مدرسة الإمام الوارث بن كعب وارتفع التركيز والتنافس إلى أعلى مستوياته فكان النجاح والتوفيق لمشروع تلاوة القرآن الكريم.
            </p>


            <h3>  المسابقة الرابعة :</h3>
            <p>
                وجاءت المحطة الرابعة للمسابقة في السنة الدراسية الخامسة حيث رجعت مرة أخرى إلى نيابة الحوقين في ضيافة مدرسة عثمان بن مظعون وتنافس طلابها مع طلاب مشروع التلاوة في ولاية السويق من مدرستي الإمام الوارث بن كعب والسويق وكانت النتيجة شبه متساوية نظرا للتركيز العالي والتلاوة المتقنة بين الطلاب العشرة المتنافسين مما أفاد المشروع في تخريج طلاب متقنين والحمد لله رب العالمين.
            </p>



            <h3>   المسابقة الخامسة :</h3>
            <p>
                أقيمت المحطة الخامسة لمسابقة التحدي في السنة الدراسية السادسة بين عشرة طلاب من مدرستي السويق والإمام الوارث بن كعب في ضيافة مدرسة السويق وقد اشتد التنافس بين الطلاب بشكل كبير مما جعل الفارق بين المتسابقين ضئيلا وفي نهاية المسابقة حصلت مدرسة السويق على درع مسابقة التحدي الخامسة.
            </p>



            <h3>   المسابقة السادسة :</h3>
            <p>
                أقيمت المحطة السادسة لمسابقة التحدي في سنة – سبع سمان - بين عشرة طلاب من مدرستي السويق والإمام الوارث بن كعب في ضيافة مدرسة السويق وقد اشتد التنافس بين طلاب المدرستين بشكل حماسي يبهج النفس محققا مستويات عالية في تلاوة القرآن مما يؤكد نجاح هذه المسابقة سنة بعد سنة وفي نهاية المسابقة حصلت مدرسة الإمام الوارث بن كعب على درع مسابقة التحدي السادسة.
            </p>



            <h3>   المسابقة السابعة :</h3>
            <p>
                أقيمت المحطة السابعة لمسابقة التحدي في سنة – ثماني حجج - بين عشرة طلاب من مدرستي ثابت بن كعب والسويق في ضيافة مدرسة ثابت بن كعب وقد أظهر الطلاب تنافسا شريفا وتحديا شائقا وأبانوا عن ارتفاع مستواهم في الدروس المخصصة في هذه السنة الدراسية ولتثبت هذه المسابقة قوتها ونجاحها في رفع مستوى الطلاب وإتقانهم للتلاوة سنة بعد سنة وفي نهاية المسابقة حصلت مدرسة ثابت بن كعب على درع مسابقة التحدي السابعة.
            </p>


            <h3>   المسابقة الثامنة :</h3>
            <p>
                أقيمت المحطة الثامنة لمسابقة التحدي في سنة – وازدادوا تسعا - بين عشرة طلاب من مدرستي ثابت بن كعب والأحنف بن قيس في ضيافة مدرسة ثابت بن كعب وفيها أظهر الطلاب إبداعهم وتطوير مستواهم وقد كشفت هذه النسخة عن حجم التنافس الشريف والتحدي الشائق بين طلاب المشروع لتثبت هذه المسابقة قوتها ونجاحها في رفع مستوى الطلاب وإتقانهم للتلاوة سنة بعد سنة وفي نهاية المسابقة حصلت مدرسة الأحنف بن قيس على درع مسابقة التحدي الثامنة.
            </p>


            <h3>
                المسابقة التاسعة :
            </h3>
            <p>
                أقيمت المحطة التاسعة لمسابقة التحدي في سنة – حصاد العشر - بين عشرة طلاب من مدرستي عثمان بن مظعون والأحنف بن قيس في ضيافة مدرسة عثمان بن مظعون وقد اشتد التنافس بين طلاب المدرستين بشكل حماسي يبهج النفس محققا مستويات عالية في تلاوة القرآن مما يؤكد نجاح هذه المسابقة سنة بعد سنة وفي نهاية المسابقة حصلت مدرسة الأحنف بن قيس على درع مسابقة التحدي التاسعة.
            </p>


        </div>
    </div>
</div>



