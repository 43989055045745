import { Component, OnInit } from '@angular/core';
import { RegistrationModule } from '../../../appshared/Main/registration/registration.module';
import { HttpErrorResponse } from '@angular/common/http';
import { RegistrationService } from '../../../appshared/Main/registration/registration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StudentModule } from '../../../appshared/Main/student/student.module';
import { StudentYearModule } from '../../../appshared/Main/student-year/student-year.module';
import { StudentService } from '../../../appshared/Main/student/student.service';
import { StudentYearService } from '../../../appshared/Main/student-year/student-year.service';
import { AddressService } from '../../../appshared/masters/address/address.service';
import { SchoolService } from '../../../appshared/Main/school/school.service';
import { AcademicYearService } from '../../../appshared/Main/academic-year/academic-year.service';
import { AcademicYearModule } from '../../../appshared/Main/academic-year/academic-year.module';
import { SchoolModule } from '../../../appshared/Main/school/school.module';
import { AddressModule } from '../../../appshared/masters/address/address.module';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { MouseEvent } from '@agm/core';

@Component({
  selector: 'app-step3-details',
  templateUrl: './step3-details.component.html',
  styleUrls: ['./step3-details.component.scss']
})
export class Step3DetailsComponent implements OnInit {

    isActive: boolean = false;
    public rid: number = 0;
    public tid: number = 0;
    public phone: string = '';
    private sub: any;
    mainForm = new RegistrationModule();
    mainFormStudent = new StudentModule();
    mainFormStudentYear = new StudentYearModule();

    public isDisabled: boolean = false;
    bsValue = new Date();
    maxDate = new Date();


    public AcademicYearData: AcademicYearModule[];
    public mnSchoolData: SchoolModule[];
    public gnAddressData: AddressModule[];
    constructor(public service: RegistrationService, private route: ActivatedRoute, private router: Router,
        public servStudent: StudentService,
        public servAcademicYear: AcademicYearService,
        public servSchool: SchoolService, private toastr: ToastrService, 
        public servAddress: AddressService,
        public servStudentYear: StudentYearService) { }

    ngOnInit(): void {
        this.sub = this.route.params.subscribe(params => {
            this.rid = +params['rid'];
            this.phone = params['phone'];
            this.checkAvailbale();
        });
        this.getAllAcademicYear();
        this.getAllSchool();
        this.getAllAddress();
    }


    public getAllAcademicYear = () => {
        this.servAcademicYear.getService()
            .subscribe(res => {
                this.AcademicYearData = res as AcademicYearModule[];
            })
    }

    public getAllSchool = () => {
        this.servSchool.getService()
            .subscribe(res => {
                this.mnSchoolData = res as SchoolModule[];
            })
    }

    public getAllAddress = () => {
        this.servAddress.getServiceParent(1, 0, "Wilayat")
            .subscribe(res => {
                this.gnAddressData = res as AddressModule[];
            })
    }

    convetMoment(startDate: Date): Date {
        let momentToDate = moment(startDate).toDate();
        return new Date(Date.UTC(momentToDate.getFullYear(), momentToDate.getMonth(), momentToDate.getDate(), momentToDate.getHours(), momentToDate.getMinutes(), momentToDate.getSeconds()));
    }

    checkAvailbale() {

        this.service.getServiceFindParent(this.phone, this.rid)
            .subscribe(res => {
                this.isDisabled = false;

                var dataReg = res as RegistrationModule[];
                this.mainForm = dataReg[0];

                this.mainForm.dob = this.convetMoment(this.mainForm.dob);

               // this.mainForm.dob = new Date(this.mainForm.dob.getFullYear(), this.mainForm.dob.getMonth(), this.mainForm.dob.getDate())


                this.tid = dataReg[0].gnTermsID;
                this.isActive = true;

            },
                (error: HttpErrorResponse) => {

                    this.router.navigate(['not_available']);

                });
    }


    serviceData2: any;
    serviceData3: any;

    onClickBT() {

        this.isDisabled = true;
        this.mainForm.status = "Accepted";

        this.servStudent.getServiceSearchCivilID(this.mainForm.civilID)
            .subscribe(res => {

     
                if (res.length > 0) {


                    this.serviceData2 = res[0];

                
                    this.servStudentYear.getServiceSearchmnStudentID(this.serviceData2.mnStudentID)
                        .subscribe(res2 => {

                         
                            this.serviceData3 = res2;
                            if (this.serviceData3.length > 0) {
                                this.toastr.success('هذا الطالب مسجل مسبقاً', '');

                            }



                        },
                            err => {
                               






                                this.mainFormStudentYear.mnStudentID = this.serviceData2.mnStudentID;
                                this.mainFormStudentYear.class = this.mainForm.class;
                                this.mainFormStudentYear.mnSchoolID = this.mainForm.mnSchoolID;
                                this.mainFormStudentYear.mnAcademicYearID = this.mainForm.mnAcademicYearID;



                          
                                this.servStudentYear.postService(this.mainFormStudentYear).subscribe(
                                    res3 => {

                                        this.SendNotification("registration-parent-approval");
                                        this.updateRecord();
                                    },
                                    err => { console.log(err); this.isDisabled = false; }
                                );

                            }
                        );


                }
                else {

                    this.mainFormStudent.civilID = this.mainForm.civilID;
                    this.mainFormStudent.gnAddressID = +this.mainForm.gnAddressID;
                    this.mainFormStudent.studentName = this.mainForm.name;
                    this.mainFormStudent.phone = this.mainForm.phoneNo;
                    this.mainFormStudent.otherPhone = this.mainForm.otherPhoneNo;
                    this.mainFormStudent.village = this.mainForm.village;
                    this.mainFormStudent.dob = this.mainForm.dob;
                  //  this.mainFormStudent.dob = this.mainForm.dob;

                
                    this.servStudent.postService(this.mainFormStudent).subscribe(
                        res2 => {

                   


                            this.serviceData3 = res2;

                            this.mainFormStudentYear.mnStudentID = this.serviceData3.mnStudentID;
                            this.mainFormStudentYear.class = this.mainForm.class;
                            this.mainFormStudentYear.mnSchoolID = this.mainForm.mnSchoolID;
                            this.mainFormStudentYear.mnAcademicYearID = this.mainForm.mnAcademicYearID;



                       
                            this.servStudentYear.postService(this.mainFormStudentYear).subscribe(
                                res3 => {
                             

                                    this.updateRecord();
                                },
                                err => { console.log(err); this.isDisabled = false; }
                            );



                        },
                        err => { console.log(err); this.isDisabled = false; }
                    );
                }
            },
                err => {

                    this.isDisabled = false;
                }
            );
        // 
    }

    updateRecord() {
       // this.loader.open();
        this.service.putService(this.mainForm).subscribe(
            res => {
                this.router.navigate(['confirm_step5', { rid: this.rid, phone: this.phone, tid: this.tid }]);
            },
            err => { console.log(err); }
        );
    }

    SendNotification(type: string) {
       // this.loader.open();
        this.service.getServiceSendMessage(this.mainForm.mnRegistrationID, type).subscribe(
            res => {
                this.toastr.success('تم الحفظ بنجاح', '');
               // this.loader.close();
                this.updateRecord();
            },
            err => {
                this.toastr.success('تم الحفظ بنجاح', '');
               // this.loader.close();
                this.updateRecord();
            }
        );
    }

    ///////////////////////////////////////////////// map



    // google maps zoom level
    zoom: number = 8;

    // initial center position for the map
    lat: number = 23.63347310330337;
    lng: number = 58.19480379931641;

    //clickedMarker(label: string, index: number) {
    //  console.log(`clicked the marker: ${label || index}`)
    //}

    getPosition(): Promise<any> {
        return new Promise((resolve, reject) => {

            navigator.geolocation.getCurrentPosition(resp => {

                resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
            },
                err => {
                    reject(err);
                });
        });

    }



    getLocation() {
        this.getPosition().then(pos => {
            this.lat = pos.lat;
            this.lng = pos.lng;
            this.zoom = 15;

            this.markers[0].lat = pos.lat;
            this.markers[0].lng = pos.lng;

            this.mainFormStudent.latitude = this.lat.toString();
            this.mainFormStudent.longitude = this.lng.toString();


        });
    }

    markerDragEnd(m: marker, $event: MouseEvent) {

        this.mainFormStudent.latitude = $event.coords.lat.toString();
        this.mainFormStudent.longitude = $event.coords.lng.toString();
    }
    // 24.377078, 56.738772
    markers: marker[] = [
        {
            lat: 23.63347310330337,
            lng: 58.19480379931641,
            label: '',
            draggable: true
        }
    ]


}


// just an interface for type safety.
interface marker {
    lat: number;
    lng: number;
    label?: string;
    draggable: boolean;
}

