import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StudentYearModule } from './student-year.module';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StudentYearService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    getService(): Observable<StudentYearModule[]> {
        return this.http.get<StudentYearModule[]>(environment.apiURL + 'Main/MNStudentYear/');
    }

    getServiceId(id: number): Observable<StudentYearModule[]> {
        return this.http.get<StudentYearModule[]>(environment.apiURL + 'Main/MNStudentYear/' + id);
    }

    getServiceSearchmnStudentID(mnStudentID: number): Observable<StudentYearModule[]> {
        return this.http.get<StudentYearModule[]>(environment.apiURL + 'Main/MNStudentYear/SearchmnStudentID/?mnStudentID=' + mnStudentID);
    }


    getServiceSendMessage(mnStudentYearID: number, type: string) {
        return this.http.get(environment.apiURL + "View/ViewMNStudentYear/SendMessage/?mnStudentYearID=" + mnStudentYearID + "&type=" + type, { responseType: 'text' });
    }

    postService(formData: StudentYearModule) {
        return this.http.post(environment.apiURL + 'Main/MNStudentYear/', formData);
    }

}
