<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>تواصل معنا</h2>
        </div>
    </div>
</div>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="contact-inner">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-features-list">
                        <h3>نحن نحب أن نستمع منك</h3>
                        <p>نرحب باقتراحاتكم واستفساراتكم في سبيل الرقي بالمشروع</p>
                        <p>  يرجى ملء النموذج ، وسيتم التواصل معكم في أقرب فرصة ممكنة.</p>
                        <p>  أو تواصلوا معنا عبر:</p>

                        <ul class="footer-contact-info">
                            <li>الموقع: <a href="#">سلطنة عمان، ولاية السويق</a></li>
                            <li>البريد الإلكتروني: <a href="mailto:info@telawh.com">info@telawh.com</a></li>
                            <li>رقم الهاتف: <a href="tel:+96895159021">95159021 968+</a></li>
                        </ul>



                    </div>

                    <div class="single-footer-widget">

                        <ul class="social">
                            <li><a href="https://www.facebook.com/qurantlawah/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="https://twitter.com/saifalkiumi" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="https://m.youtube.com/@qurantelawh" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                            <li><a href="https://www.instagram.com/telawh.om" target="_blank"><i class="bx bxl-instagram-alt"></i></a></li>
                            <li><a href="https://whatsapp.com/channel/0029VaOCwA05kg7FQQuRP637" target="_blank"><i class="bx bxl-whatsapp-square"></i></a></li>
                        </ul>
                    </div>

                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <h3>اكتب لنا</h3>
                        <form novalidate autocomplete="off" #form="ngForm" (submit)="onSubmit(form)">
                            <div class="row">
                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group mb-3">
                                        <label>الاسم <span>*</span></label>
                                        <input type="text" name="name" id="name" #name="ngModel" [(ngModel)]="service.formData.name" class="form-control" placeholder="" required>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group mb-3">
                                        <label>البريد الإلكتروني <span>*</span></label>
                                        <input type="email" name="email" id="email" #email="ngModel" [(ngModel)]="service.formData.email" class="form-control" placeholder="" required>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <label>رقم الهاتف <span>*</span></label>
                                        <input type="text" name="phone_number" id="phone_number" #enquiry_PhoneNumber="ngModel" [(ngModel)]="service.formData.phoneNo" class="form-control" placeholder="" required>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <label>نوع الاستفسار <span>*</span></label>
                                        <select required #gnCategoryID="ngModel" name="gnCategoryID" [(ngModel)]="service.formData.gnCategoryID" class="form-control" style="padding-top:10px">
                                            <option *ngFor="let option of categoryData" [value]="option.gnCategoryID">{{ option.nameAr }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <label>رسالتك <span>*</span></label>
                                        <textarea name="message" id="message" cols="30" rows="5" #message="ngModel" [(ngModel)]="service.formData.message" class="form-control" placeholder="" required></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn" [disabled]="form.invalid"><i class='bx bxs-paper-plane'></i>إرسال<span></span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        
        </div>
    </div>
</section>


