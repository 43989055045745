import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CategoryModule } from '../../appshared/masters/category/category.module';
import { CategoryService } from '../../appshared/masters/category/category.service';
import { FeedbackService } from '../../appshared/webTools/feedback/feedback.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

    public categoryData: CategoryModule[];
    constructor(public service: FeedbackService, public servCategory: CategoryService, private router: Router, private toastr: ToastrService) { }

    ngOnInit(): void {
        this.getAllCategory();
        this.service.formData.gnCategoryID = 1;
        this.service.formData.status = "Waiting";
  }


    public getAllCategory = () => {
        this.servCategory.getCategory("Enguiry Type")
            .subscribe(res => {
                this.categoryData = res as CategoryModule[];
            })
    }

    onSubmit(form: NgForm) {
        if (this.service.formData.wbFeedbackID == 0)
            this.insertRecord(form);
    }

    insertRecord(form: NgForm) {

        this.service.postService().subscribe(
            res => {
                /* this.serviceData2 = res as CategoryModule;*/

                this.toastr.success('تم إرسال استفساركم بنجاح', '');
                this.router.navigate(['/']);
            },
            err => { console.log(err); }
        );
    }
}
