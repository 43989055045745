<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>اعتماد الطالب</h2>
            <p>خطأ</p>
        </div>
    </div>
</div>

<div class="service-details ptb-100">
    <div class="container">
        <div class="row align-items-center">




            <div class="">
                <div class="content left-content">
                    <h4>هذه الصفحة غير متاحة حاليا</h4>
                    

                    


                    

                </div>
            </div>



        </div>
    </div>
</div>
