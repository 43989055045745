export class StudentModule {
    mnStudentID: number = 0;
    gnAddressID: number = 0;
    studentName: string = '';
    phone: string = '';
    otherPhone: string = '';
    village: string = '';
    civilID: string = '';
    note: string = '';
    accommodationDescription: string = '';
    gnTermsID: number = 0;
    dob: Date = new Date();
    latitude: string = '';
    longitude: string = '';
}
