import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StudentModule } from './student.module';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StudentService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }


    getService(): Observable<StudentModule[]> {
        return this.http.get<StudentModule[]>(environment.apiURL + 'Main/MNStudent/');
    }


  

    getServiceId(id: number): Observable<StudentModule[]> {
        return this.http.get<StudentModule[]>(environment.apiURL + 'Main/MNStudent/' + id);
    }

    getServiceSearchCivilID(CivilID: string): Observable<StudentModule[]> {
        return this.http.get<StudentModule[]>(environment.apiURL + 'Main/MNStudent/SearchCivilID/?CivilID=' + CivilID);
    }


    postService(formData: StudentModule) {
        //formData.dob = this.convetMoment(formData.dob);
        return this.http.post(environment.apiURL + 'Main/MNStudent/', formData);
    }
}
