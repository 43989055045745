import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IndividualRecitationModule, ViewIndividualRecitationModule } from './individual-recitation.module';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IndividualRecitationService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }


    getService(): Observable<IndividualRecitationModule[]> {
        return this.http.get<IndividualRecitationModule[]>(environment.apiURL + 'Main/MNIndividualRecitation/');
    }

    getServiceId(id: number): Observable<IndividualRecitationModule[]> {
        return this.http.get<IndividualRecitationModule[]>(environment.apiURL + 'Main/MNIndividualRecitation/' + id);
    }

    getServiceStudentId(StudentId: number): Observable<ViewIndividualRecitationModule[]> {
        return this.http.get<ViewIndividualRecitationModule[]>(environment.apiURL + 'View/ViewIndividualRecitation/SearchStudent/?StudentID=' + StudentId);
    }

    getServiceLastStudentId(StudentId: number): Observable<ViewIndividualRecitationModule[]> {
        return this.http.get<ViewIndividualRecitationModule[]>(environment.apiURL + 'View/ViewIndividualRecitation/LastStudent/?StudentID=' + StudentId);
    }
}
