import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExamModule, ViewExamModule } from './exam.module';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExamService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }


    getService(): Observable<ExamModule[]> {
        return this.http.get<ExamModule[]>(environment.apiURL + 'Main/MNExam/');
    }

    getServiceId(id: number): Observable<ExamModule[]> {
        return this.http.get<ExamModule[]>(environment.apiURL + 'Main/MNExam/' + id);
    }


    getServiceStudentId(StudentId: number, type: string): Observable<ViewExamModule[]> {
        return this.http.get<ViewExamModule[]>(environment.apiURL + 'View/ViewExam/SearchStudent/?StudentID=' + StudentId + '&type=' + type);
    }

    getServiceLastStudentId(StudentId: number): Observable<ViewExamModule[]> {
        return this.http.get<ViewExamModule[]>(environment.apiURL + 'View/ViewExam/LastStudent/?StudentID=' + StudentId);
    }

    getServiceMostExamQuantity(yearID: number) {
        return this.http.get<ViewExamModule[]>(environment.apiURL + 'View/ViewExam/MostExamQuantity/?yearID=' + yearID);
    }
}
