<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>اعتماد الطالب</h2>
            <p></p>
        </div>
    </div>
</div>






<section class="video-presentation-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>تم التسجيل بنجاح</h2>
        </div>


        <div class="contact-cta-box mwidth-1000 wow animate__animated animate__fadeInUp" data-wow-delay="1s">
            <!--<h3>رقم الطلب هو {{thisID}}</h3>-->
            <a routerLink="/" class="default-btn"><i class="bx bxs-home-alt-2"></i> الذهاب للرئيسية<span></span></a>
        </div>
    </div>
    <div class="shape-map1"><img src="assets/img/map1.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/shape9.png" alt="image"></div>
</section>

