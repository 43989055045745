import { Component, OnInit, ViewChild } from '@angular/core';
import { StudentYearService } from '../../appshared/Main/student-year/student-year.service';
import { StudentService } from '../../appshared/Main/student/student.service';
import { SchoolService } from '../../appshared/Main/school/school.service';
import { LevelService } from '../../appshared/masters/level/level.service';
import { GroupService } from '../../appshared/masters/group/group.service';
import { BusService } from '../../appshared/masters/bus/bus.service';
import { LocationService } from '../../appshared/masters/location/location.service';
import { AddressService } from '../../appshared/masters/address/address.service';
import { AcademicYearService } from '../../appshared/Main/academic-year/academic-year.service';
import { ExamService } from '../../appshared/Main/exam/exam.service';
import { RecitationLecturesAttendanceService } from '../../appshared/Main/recitation-lectures-attendance/recitation-lectures-attendance.service';
import { IndividualRecitationService } from '../../appshared/Main/individual-recitation/individual-recitation.service';
import { MemorizationLectureService } from '../../appshared/Main/memorization-lecture/memorization-lecture.service';
import { RecitationLecturesAttendanceModule } from '../../appshared/Main/recitation-lectures-attendance/recitation-lectures-attendance.module';
import { ExamModule } from '../../appshared/Main/exam/exam.module';
import { MemorizationLectureModule } from '../../appshared/Main/memorization-lecture/memorization-lecture.module';
import { IndividualRecitationModule } from '../../appshared/Main/individual-recitation/individual-recitation.module';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ViewStudentYearModule } from '../../appshared/view/view-student-year/view-student-year.module';
import { ViewStudentYearService } from '../../appshared/view/view-student-year/view-student-year.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-student-details',
  templateUrl: './student-details.component.html',
  styleUrls: ['./student-details.component.scss']
})
export class StudentDetailsComponent implements OnInit {

    thisID: number = 0;
    private sub: any;
    private datas: any;
    mainForm = new ViewStudentYearModule();
    constructor(public service: ViewStudentYearService,
        public servStudentYear: StudentYearService,
        public servStudent: StudentService,
        public servSchool: SchoolService,
        public servLevel: LevelService,
        public servGroup: GroupService,
        public servBus: BusService,
        public servLocation: LocationService,
        public servAddress: AddressService,
        public servAcademicYear: AcademicYearService,
        public servMemorizationLecture: MemorizationLectureService,
        public servIndividualRecitation: IndividualRecitationService,
        public servRecitationLecturesAttendance: RecitationLecturesAttendanceService,
        private router: Router,
        private route: ActivatedRoute,
        public servExam: ExamService
    ) { }

    ngOnInit(): void {
        this.sub = this.route.params.subscribe(params => {
            this.thisID = +params['id'];
        });
        if (this.thisID.toString() != "NaN") {
           
            this.buildItemForm();
        }
        else {

            this.router.navigate(['not_available']);

        }


  }

    buildItemForm() {

        this.service.getServiceId(this.thisID)
            .subscribe(res => {

                this.datas = res as ViewStudentYearModule[];

                this.mainForm = this.datas as ViewStudentYearModule;

                this.getAllServiceRecitationLecturesAttendance();
                this.getAllServiceOralExam();
                this.getAllServiceIndividualRecitation();
                this.getAllServiceMemorizationLecture();
                this.getAllServicePreparatoryExam();
                this.getAllServiceWrittenExam();
                this.getAllServiceMemorizationReviewLecture();

            },
                (error: HttpErrorResponse) => {

                    this.router.navigate(['not_available']);

                });

    }



    ngAfterViewInit(): void {
        this.dataSourceMemorizationLecture.sort = this.sortMemorizationLecture;
        this.dataSourceMemorizationLecture.paginator = this.paginatorMemorizationLecture;

        this.dataSourceMemorizationReviewLecture.sort = this.sortMemorizationReviewLecture;
        this.dataSourceMemorizationReviewLecture.paginator = this.paginatorMemorizationReviewLecture;

        this.dataSourceIndividualRecitation.sort = this.sortIndividualRecitation;
        this.dataSourceIndividualRecitation.paginator = this.paginatorIndividualRecitation;

        this.dataSourcePreparatoryExam.sort = this.sortPreparatoryExam;
        this.dataSourcePreparatoryExam.paginator = this.paginatorPreparatoryExam;

        this.dataSourceWrittenExam.sort = this.sortWrittenExam;
        this.dataSourceWrittenExam.paginator = this.paginatorWrittenExam;

        this.dataSourceOralExam.sort = this.sortOralExam;
        this.dataSourceOralExam.paginator = this.paginatorOralExam;

        this.dataSourceRecitationLecturesAttendance.sort = this.sortRecitationLecturesAttendance;
        this.dataSourceRecitationLecturesAttendance.paginator = this.paginatorRecitationLecturesAttendance;


    }


    ///////////////////////////////////////////////////////الحضور


    public dataSourceRecitationLecturesAttendance = new MatTableDataSource<RecitationLecturesAttendanceModule>();
    displayedColumnsRecitationLecturesAttendance = ['num', 'date', 'isAttend', 'teacherName', 'courseTelawa', 'subject', 'notes'];
    @ViewChild('sortRecitationLecturesAttendance') sortRecitationLecturesAttendance: MatSort;
    @ViewChild('paginatorRecitationLecturesAttendance') paginatorRecitationLecturesAttendance: MatPaginator;

    public getAllServiceRecitationLecturesAttendance = () => {

        this.dataSourceRecitationLecturesAttendance.data = null;
        this.servRecitationLecturesAttendance.getServiceStudentId(this.mainForm.mnStudentID)
            .subscribe(res => {
                this.dataSourceRecitationLecturesAttendance.data = res as RecitationLecturesAttendanceModule[];
            })
    }


    ///////////////////////////////////////////////////////المراجعة


    public dataSourceMemorizationReviewLecture = new MatTableDataSource<MemorizationLectureModule>();
    displayedColumnsMemorizationReviewLecture = ['num', 'date', 'teacherName', 'chapterName', 'verseNo', 'notes'];
    @ViewChild('sortMemorizationReviewLecture') sortMemorizationReviewLecture: MatSort;
    @ViewChild('paginatorMemorizationReviewLecture') paginatorMemorizationReviewLecture: MatPaginator;

    public getAllServiceMemorizationReviewLecture = () => {

        this.dataSourceMemorizationReviewLecture.data = null;
        this.servMemorizationLecture.getServiceStudentId(this.mainForm.mnStudentID, 'المراجعة')
            .subscribe(res => {
                this.dataSourceMemorizationReviewLecture.data = res as MemorizationLectureModule[];
            })
    }

    /////////////////////////////////////////////////////// الحفظ


    public dataSourceMemorizationLecture = new MatTableDataSource<MemorizationLectureModule>();
    displayedColumnsMemorizationLecture = ['num', 'date', 'teacherName', 'chapterName', 'verseNo', 'notes'];
    @ViewChild('sortMemorizationLecture') sortMemorizationLecture: MatSort;
    @ViewChild('paginatorMemorizationLecture') paginatorMemorizationLecture: MatPaginator;

    public getAllServiceMemorizationLecture = () => {

        this.dataSourceMemorizationLecture.data = null;
        this.servMemorizationLecture.getServiceStudentId(this.mainForm.mnStudentID, 'الحفظ')
            .subscribe(res => {
                this.dataSourceMemorizationLecture.data = res as MemorizationLectureModule[];
            })
    }



    /////////////////////////////////////////////////////// التلاوة الفردية


    public dataSourceIndividualRecitation = new MatTableDataSource<IndividualRecitationModule>();
    displayedColumnsIndividualRecitation = ['num', 'date', 'teacherName', 'chapterName', 'verseNo', 'notes'];
    @ViewChild('sortIndividualRecitation') sortIndividualRecitation: MatSort;
    @ViewChild('paginatorIndividualRecitation') paginatorIndividualRecitation: MatPaginator;

    public getAllServiceIndividualRecitation = () => {

        this.dataSourceIndividualRecitation.data = null;
        this.servIndividualRecitation.getServiceStudentId(this.mainForm.mnStudentID)
            .subscribe(res => {
                this.dataSourceIndividualRecitation.data = res as IndividualRecitationModule[];
            })
    }




    /////////////////////////////////////////////////////// الاختبار التجهيزي


    public dataSourcePreparatoryExam = new MatTableDataSource<ExamModule>();
    displayedColumnsPreparatoryExam = ['num', 'date', 'teacherName', 'chapterNo', 'status', 'note'];
    @ViewChild('sortPreparatoryExam') sortPreparatoryExam: MatSort;
    @ViewChild('paginatorPreparatoryExam') paginatorPreparatoryExam: MatPaginator;

    public getAllServicePreparatoryExam = () => {

        this.dataSourcePreparatoryExam.data = null;
        this.servExam.getServiceStudentId(this.mainForm.mnStudentID, 'اختبار تجهيزي')
            .subscribe(res => {
                this.dataSourcePreparatoryExam.data = res as ExamModule[];
            })
    }




    /////////////////////////////////////////////////////// الاختبار كتابي


    public dataSourceWrittenExam = new MatTableDataSource<ExamModule>();
    displayedColumnsWrittenExam = ['num', 'date', 'teacherName', 'chapterNo', 'mark', 'status', 'note'];
    @ViewChild('sortWrittenExam') sortWrittenExam: MatSort;
    @ViewChild('paginatorWrittenExam') paginatorWrittenExam: MatPaginator;

    public getAllServiceWrittenExam = () => {

        this.dataSourceWrittenExam.data = null;
        this.servExam.getServiceStudentId(this.mainForm.mnStudentID, 'اختبار كتابي')
            .subscribe(res => {
                this.dataSourceWrittenExam.data = res as ExamModule[];
            })
    }




    /////////////////////////////////////////////////////// الاختبار الشفوي


    public dataSourceOralExam = new MatTableDataSource<ExamModule>();
    displayedColumnsOralExam = ['num', 'date', 'teacherName', 'examNo', 'mark', 'status', 'note'];
    @ViewChild('sortOralExam') sortOralExam: MatSort;
    @ViewChild('paginatorOralExam') paginatorOralExam: MatPaginator;

    public getAllServiceOralExam = () => {

        this.dataSourceOralExam.data = null;
        this.servExam.getServiceStudentId(this.mainForm.mnStudentID, 'اختبار شفوي')
            .subscribe(res => {
                this.dataSourceOralExam.data = res as ExamModule[];
            })
    }


    ////////////////////////////////////////////////////////////


    getClassOfTxt(status: string) {

        if (status == 'اجتاز') {
            return 'text-green-500'
        }
        else {
            return 'text-red-500'
        }


    }
}
