import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { ChattingComponent } from './components/common/chatting/chatting.component';
import { HttpClientModule } from '@angular/common/http';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AgmCoreModule } from '@agm/core';
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatChipsModule } from '@angular/material/chips';

import { HomeComponent } from './components/appviews/home/home.component';
import { SchoolsComponent } from './components/appviews/schools/schools.component';
import { RegisterTermsComponent } from './components/appviews/register/register-terms/register-terms.component';
import { RegisterDetailsComponent } from './components/appviews/register/register-details/register-details.component';
import { RegisterResultComponent } from './components/appviews/register/register-result/register-result.component';
import { StoryComponent } from './components/appviews/project_progress/story/story.component';
import { FounderComponent } from './components/appviews/project_progress/founder/founder.component';
import { ReasonComponent } from './components/appviews/project_progress/reason/reason.component';
import { TargetComponent } from './components/appviews/project_progress/target/target.component';
import { ResultComponent } from './components/appviews/project_progress/result/result.component';
import { ContactUsComponent } from './components/appviews/contact-us/contact-us.component';
import { Step1AboutComponent } from './components/appviews/parent_confirm/step1-about/step1-about.component';
import { Step2TermsComponent } from './components/appviews/parent_confirm/step2-terms/step2-terms.component';
import { Step3DetailsComponent } from './components/appviews/parent_confirm/step3-details/step3-details.component';
import { Step4LocationComponent } from './components/appviews/parent_confirm/step4-location/step4-location.component';
import { Step5DoneComponent } from './components/appviews/parent_confirm/step5-done/step5-done.component';
import { NotAvailableComponent } from './components/appviews/parent_confirm/not-available/not-available.component';
import { StudentDetailsComponent } from './components/appviews/student-details/student-details.component';
import { ChallengeCompetitionComponent } from './components/appviews/project_progress/challenge-competition/challenge-competition.component';
import { MethodologyComponent } from './components/appviews/project_progress/methodology/methodology.component';
import { OnlyNumberDirective } from './components/common/only-number.directive';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    PreloaderComponent,
 
    FooterComponent,
   
    ChattingComponent,
    HomeComponent,
    SchoolsComponent,
    RegisterTermsComponent,
    RegisterDetailsComponent,
    RegisterResultComponent,
    StoryComponent,
    FounderComponent,
    ReasonComponent,
    TargetComponent,
    ResultComponent,
    ContactUsComponent,
    Step1AboutComponent,
    Step2TermsComponent,
    Step3DetailsComponent,
    Step4LocationComponent,
    Step5DoneComponent,
        NotAvailableComponent,
    StudentDetailsComponent,
    ChallengeCompetitionComponent,
        MethodologyComponent, OnlyNumberDirective
  ],
  imports: [
      BrowserModule, FormsModule, HttpClientModule, BsDatepickerModule, BrowserAnimationsModule, MatTableModule,
      ToastrModule.forRoot(),
      MatSortModule,
      MatPaginatorModule, MatChipsModule,
      AgmCoreModule.forRoot({
          apiKey: 'AIzaSyAfIzbiX48h8Hr0S4uX-YPQHQAeV3CRsWw'
      }),
    AppRoutingModule
  ],
    providers: [BsDatepickerConfig],
  bootstrap: [AppComponent]
})
export class AppModule { }
