import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { BusModule } from './bus.module';

@Injectable({
  providedIn: 'root'
})
export class BusService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };
    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    getYear(yearID: number): Observable<BusModule[]> {
        return this.http.get<BusModule[]>(environment.apiURL + 'Main/MNBus/SearchYearID/?YearID=' + yearID);
    }

    getServiceId(id: number): Observable<BusModule[]> {
        return this.http.get<BusModule[]>(environment.apiURL + 'Main/MNBus/' + id);
    }
}
